import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, map } from "rxjs";
import { IEmployee } from "src/app/core/interfaces/employee.interface";
import { EmployeeService } from "src/app/shared/services/employee.service";
import { environment } from "src/environments/environment";
import { selectSystemTime } from "../../time/reducer";
import { format } from "date-fns";
import { Employee } from "src/app/core/models/employee.model";

@Injectable({
  providedIn: "root",
})
export class UsersService {
  date: Date | null = null;
  constructor(private http: HttpClient, private employeeService: EmployeeService, private store: Store) {
    this.store.select(selectSystemTime).subscribe((date: Date) => {
      this.date = date;
    });
  }

  fetchEmployees(): Observable<Employee[]> {
    const url = environment.apiUrl + "employees";
    let formattedDate: string = "";
    if (this.date) {
      formattedDate = format(this.date, "yyyy-MM-dd");
    }
    const params = new HttpParams().set("date", formattedDate);
    return this.http.get<any>(url, { params, observe: "response" }).pipe(
      map((response: HttpResponse<any>) => {
        const employees: Employee[] = this.employeeService.employeesFromApi(response.body.data);
        return employees;
      })
    );
  }
}
