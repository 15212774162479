import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { UsersService } from "./services/users.service";
import { usersActions } from "./actions";
import { catchError, interval, map, of, startWith, switchMap, tap } from "rxjs";
import * as UIActions from "src/app/core/store/ui/ui.actions";
import { EmployeeService } from "src/app/shared/services/employee.service";
import { Store } from "@ngrx/store";
import { TimeService } from "../time/services/time.service";
import { environment } from "src/environments/environment";
import { Employee } from "../../models/employee.model";
import { uiActions } from "../ui/actions";

@Injectable()
export class UsersFeatureEffects {
  interval: number = 360000;

  fetchEmployees$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersActions.fetchEmployees),
      tap(() => {
        this.store.dispatch(uiActions.fetchEmployees());
      }),
      switchMap(() =>
        interval(this.interval).pipe(
          startWith(0),
          switchMap(() =>
            this.usersService.fetchEmployees().pipe(
              map((employees: Employee[]) => usersActions.fetchEmployeesSuccess({ employees })),
              catchError((error: any) => {
                usersActions.fetchEmployeesFailure();
                this.store.dispatch(uiActions.failedFetching());
                return of(new UIActions.Error(error));
              })
            )
          )
        )
      )
    )
  );

  setEmployees$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(usersActions.fetchEmployeesSuccess),
        tap((state) => {
          const employeesStates = this.employeesService.employeePresenceStates(state.employees);
          this.store.dispatch(usersActions.setEmployeesSyncTime({ payload: this.timeService.getSystemTime() }));
          this.store.dispatch(usersActions.setEmployeesStates({ payload: employeesStates }));
          this.store.dispatch(uiActions.successFetchingEmployees());
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private usersService: UsersService,
    private employeesService: EmployeeService,
    private store: Store,
    private timeService: TimeService
  ) {}
}
