export enum EUserRole {
  ADMINISTRATOR,
  BOARD,
  MANAGER,
  EMPLOYEE,
  TRAINEE,
  PARENT,
}

export enum EUserRoles {
  BOARD,
  PARENT,
  MANAGER,
  EDUCATOR,
  TRAINEE,
  STUDENT,
  FIRST_CHAIRMAN,
  SECOND_CHAIRMAN,
  THIRD_CHAIRMAN,
  TREASURER,
  USER_MANAGEMENT,
  EMPLOYEE_MANAGEMENT,
  PARENTS_MANAGEMENT,
}
