<ion-list #ionList [inset]="true">
  <ion-list-header *ngIf="notifications.length > 0">
    <div class="list-title-wrapper">
      <ion-text color="medium">
        {{ 'COMPONENTS.OPEN_ABSENCES.UNREAD' | translate }}
      </ion-text>
      <div class="badge">{{ notifications.length }}</div>
    </div>
  </ion-list-header>
  <ion-item-sliding
    *ngFor="let notification of notifications; let last = last"
    (ionDrag)="itemDragged(ionList)"
    [attr.data-notification-type]="getNotificationType(notification)"
    [attr.data-notification-editor]="getNotificationEditor(notification)"
    [attr.data-notification-date]="getNotificationDate(notification)"
    [attr.data-notification-status]="getNotificationStatusType(notification)"
  >
    <ion-item [lines]="last ? 'none' : 'full'">
      <ion-text
        class="start-icon"
        [color]="getIconColor(notification)"
        slot="start"
      >
        <fa-icon [icon]="getIconName(notification)"></fa-icon>
      </ion-text>
      <ion-label color="medium">
        <div class="message-wrapper">
          <ion-text class="message ion-text-wrap">{{
            getNotificationMessage(notification)
          }}</ion-text>
        </div>
      </ion-label>
      <ion-note class="date-chimp" slot="end">{{
        getNotificationCreatedDate(notification.created!)
      }}</ion-note>
    </ion-item>
    <ion-item-options
      (ionSwipe)="swipeHandler($event, notification)"
      side="start"
    >
      <ion-item-option
        (click)="updateNotification(notification)"
        color="app-success"
        expandable
      >
        <ion-text color="light" slot="start"
          ><fa-icon icon="check"></fa-icon
        ></ion-text>
        <ion-text color="light">{{
          'COMPONENTS.OPEN_ABSENCES.READ' | translate
        }}</ion-text>
      </ion-item-option>
    </ion-item-options>
    <ion-item-options
      (ionSwipe)="swipeHandler($event, notification)"
      side="end"
    >
      <ion-item-option
        (click)="deleteNotification(notification)"
        color="app-danger"
        expandable
      >
        <ion-text color="light" slot="start"
          ><fa-icon icon="trash-can"></fa-icon
        ></ion-text>
        <ion-text color="light">{{
          'PAGES.ACCOUNT.DELETE' | translate
        }}</ion-text>
      </ion-item-option>
    </ion-item-options>
  </ion-item-sliding>
  <ion-item
    *ngIf="notifications.length == 0"
    lines="none"
    class="ion-text-center"
  >
    <ion-label color="medium">{{
      'PAGES.ABSENT_RECORD.NO_ENTRY_FOUND' | translate
    }}</ion-label>
  </ion-item>
</ion-list>
