import { OnDestroy, Pipe, PipeTransform } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { differenceInMinutes, differenceInSeconds, differenceInHours } from "date-fns";
import { TimeService } from "src/app/core/store/time/services/time.service";

type TimeUnit = "minutes" | "seconds" | "hours";

@Pipe({
  name: "timeDiffSystemTime",
  pure: false,
})
export class TimeDiffSystemTimePipe implements PipeTransform, OnDestroy {
  private subscription$: Subscription | undefined = undefined;

  constructor(private timeService: TimeService) {}

  transform(dateObservable: Date | undefined | null, unit: TimeUnit = "minutes"): number {
    let timeDiff = 0;
    if (dateObservable) {
      const currentTime = this.timeService.getSystemTime();
      switch (unit) {
        case "seconds":
          timeDiff = differenceInSeconds(currentTime, dateObservable);
          break;
        case "hours":
          timeDiff = differenceInHours(currentTime, dateObservable);
          break;
        case "minutes":
        default:
          timeDiff = differenceInMinutes(currentTime, dateObservable);
      }
    }

    return timeDiff;
  }

  ngOnDestroy() {
    // Unsubscribe from the observable when the pipe is destroyed
    if (this.subscription$) {
      this.subscription$.unsubscribe();
    }
  }
}
