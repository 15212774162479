
import { WorkRecord } from 'src/app/core/models/work-record.model';
import * as WorkRecordsActions from './workRecords.actions';

export interface WorkRecordsState {
    workRecords: WorkRecord[]
}

const initialState: WorkRecordsState = {
    workRecords: []
};

export function workRecordsReducer(state = initialState, action: WorkRecordsActions.WorkRecordsActions): WorkRecordsState {

    switch (action.type) {
        case WorkRecordsActions.SET_WORKRECORD:
            return {
                ...state,
                workRecords: [...action.payload]
            }
        default:
            return state;
    }
}