import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, from, map, of, switchMap, tap } from "rxjs";
import { settingsActions } from "./actions";
import { StorageService } from "src/app/shared/services/storage.service";
import { NGXLogger } from "ngx-logger";
import { EStorageKey } from "../../enums/storage-key.enum";
import * as UIActions from "../ui/ui.actions";
import { TranslateService } from "@ngx-translate/core";
import { SettingsService } from "./services/settings.service";
import { EMessageType } from "../../enums/message-type.enum";
import { Store } from "@ngrx/store";

/**
 * Effects class for handling settings-related actions in the application.
 */
@Injectable()
export class SettingsFeatureEffects {
  fetchSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(settingsActions.fetchSettings),
      switchMap(() => {
        this.logger.info("Fetching Settings from locale store");
        return from(
          this.storage.get(EStorageKey.SETTINGS).then((value) => {
            return value;
          })
        );
      }),
      map((settings) => {
        if (settings) {
          return settingsActions.fetchSettingsSuccess({ settings: settings });
        } else {
          return { type: "NO_ACTION" };
        }
      }),
      catchError((error) => {
        return of(new UIActions.Error({ error: error, internal: false }));
      })
    )
  );

  fetchSettingsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(settingsActions.fetchSettingsSuccess),
        tap((action) => {
          this.logger.info("Fetched settings successfully");
          this.store.dispatch(settingsActions.setLanguage({ language: action.settings.language }));
        }),
        catchError((error) => {
          return of(new UIActions.Error({ error: error, internal: false }));
        })
      ),
    { dispatch: false }
  );

  saveSettings$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(settingsActions.saveSettingsInLocaleStore),
        tap(() => {
          this.logger.info("Saving settings in locale store");
          this.settingsService.saveSettingsInLocaleStore();
          this.logger.info("Settings saved in locale store successfully");
        }),
        catchError((error) => {
          return of(new UIActions.Error({ error: error, internal: false }));
        })
      ),
    { dispatch: false }
  );

  fetchLogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(settingsActions.fetchLogs),
      switchMap(() => {
        this.logger.info("Fetching Logs from locale store");
        return from(
          this.storage.get(EStorageKey.LOGS).then((value) => {
            return value;
          })
        );
      }),
      map((logs) => {
        if (logs) {
          return settingsActions.fetchLogsSuccess({ logs: logs });
        } else {
          return { type: "NO_ACTION" };
        }
      }),
      catchError((error) => {
        return of(new UIActions.Error({ error: error, internal: false }));
      })
    )
  );

  saveLogs$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(settingsActions.saveLogsInLocaleStore),
        tap(() => {
          this.settingsService.saveLogsInLocaleStore();
        }),
        catchError((error) => {
          return of(new UIActions.Error({ error: error, internal: false }));
        })
      ),
    { dispatch: false }
  );

  setLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(settingsActions.setLanguage),
      switchMap((action) => {
        this.logger.info("Setting language");
        this.translateService.use(action.language);
        return of(settingsActions.saveSettingsInLocaleStore());
      }),
      catchError((error) => {
        return of(new UIActions.Error({ error: error, internal: false }));
      })
    )
  );

  setTeamCalendarCollapse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(settingsActions.toggleTeamCalendar),
      switchMap((action) => {
        this.logger.info("Setting team calendar collapse");
        return of(settingsActions.saveSettingsInLocaleStore());
      }),
      catchError((error) => {
        return of(new UIActions.Error({ error: error, internal: false }));
      })
    )
  );

  setCustomTime$ = createEffect(() =>
    this.actions$.pipe(
      ofType(settingsActions.useCustomTime),
      switchMap((action) => {
        this.logger.info("Setting custom time");
        return of(settingsActions.saveSettingsInLocaleStore());
      }),
      catchError((error) => {
        return of(new UIActions.Error({ error: error, internal: false }));
      })
    )
  );

  addNewLogMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(settingsActions.addNewLogMessage),
        tap((action) => {
          this.store.dispatch(settingsActions.saveLogsInLocaleStore());
        }),
        catchError((error) => {
          return of(new UIActions.Error({ error: error, internal: false }));
        })
      ),
    { dispatch: false }
  );

  sendLogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(settingsActions.sendLogsToServer),
      switchMap((action) => {
        this.logger.info("Sending logs to server");
        return this.settingsService.sendLogsToServer(action.message).pipe(
          map(() => {
            return new UIActions.Message({
              message: this.translateService.instant("CORE.MESSAGES.SUPPORT_FILE_SEND"),
              type: EMessageType.INFORMATION,
            });
          }),
          catchError((error) => {
            this.logger.error(error.message);
            return of(new UIActions.Error({ error: error, internal: false }));
          })
        );
      }),
      catchError((error) => {
        return of(new UIActions.Error({ error: error, internal: false }));
      })
    )
  );

  resetLogs$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(settingsActions.resetLogsFromStorage),
        switchMap(() => {
          this.logger.info("Resetting logs from locale store");
          return from(this.storage.remove(EStorageKey.LOGS));
        }),
        tap(() => {
          this.logger.info("Logs removed from locale store successfully");
        }),
        catchError((error) => {
          return of(new UIActions.Error({ error: error, internal: false }));
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private logger: NGXLogger,
    private settingsService: SettingsService,
    private storage: StorageService,
    private translateService: TranslateService,
    private store: Store
  ) {}
}
