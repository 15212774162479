import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { IAppState } from "./core/store/app/app.reducer";
import { AlertButton, AlertController, NavController, Platform } from "@ionic/angular";
import { EMessageType } from "./core/enums/message-type.enum";
import { Observable, Subscription, combineLatest } from "rxjs";
import { UIState } from "./core/store/ui/ui.reducer";
import { AuthState } from "./core/store/auth/auth.reducer";
import { LanguageService } from "./shared/services/language.service";
import { NGXLogger } from "ngx-logger";
import { TranslateService } from "@ngx-translate/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { SharedService } from "./shared/services/shared.service";
import * as AuthActions from "./core/store/auth/auth.actions";
import * as UIActions from "./core/store/ui/ui.actions";
import { timeActions } from "./core/store/time/actions";
import { UpdateService } from "./shared/services/update.service";
import { settingsActions } from "./core/store/settings/actions";
import { getUnixTime } from "date-fns";
@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  alertTitle = "";
  alertMessage = "";
  public supportFile = false;
  public isLoading = false;
  private alertShown = false;
  public authorized: boolean = false;
  public showSplash = true;
  private messageType!: EMessageType;
  private combinedStates$!: Observable<[any, any]>;
  private combinedStatesSubscription$!: Subscription;
  private isBackground = false;

  constructor(
    private store: Store<IAppState>,
    private alertController: AlertController,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private logger: NGXLogger,
    private platform: Platform,
    private route: ActivatedRoute,
    private router: Router,
    private updateService: UpdateService,
    private sharedService: SharedService
  ) {}

  @HostListener("document:visibilitychange", ["$event"])
  visibilitychange() {
    this.appIsBackground();
  }

  ngOnInit(): void {
    this.platform.ready().then(() => {
      this.logger.info("********** App started **********");

      this.languageService.setInitialAppLanguage();
      this.store.dispatch(new AuthActions.AutoLogin());
      this.store.dispatch(settingsActions.fetchSettings());
      this.store.dispatch(settingsActions.fetchLogs());
      this.store.dispatch(timeActions.fetchTime());

      this.combinedStates$ = combineLatest([this.store.select("account"), this.store.select("ui")]);

      this.combinedStatesSubscription$ = this.combinedStates$.subscribe(([account, ui]: [AuthState, UIState]) => {
        this.showSplash = ui.showSplash;

        account.account ? (this.authorized = true) : (this.authorized = false);

        if (ui.message) {
          switch (ui.type) {
            case EMessageType.INFORMATION:
              this.alertTitle = this.translateService.instant("CORE.MESSAGES.INFORMATION");
              this.messageType = EMessageType.INFORMATION;
              break;
            case EMessageType.WARNING:
              this.alertTitle = this.translateService.instant("CORE.MESSAGES.WARNING");
              this.messageType = EMessageType.WARNING;
              break;
            case EMessageType.ERROR:
              this.alertTitle = this.translateService.instant("CORE.MESSAGES.ERROR");
              this.messageType = EMessageType.ERROR;
              break;
            case EMessageType.UPDATE:
              this.alertTitle = this.translateService.instant("CORE.MESSAGES.INFORMATION");
              this.messageType = EMessageType.UPDATE;
              break;
          }

          this.supportFile = ui.supportFile;

          this.alertMessage = ui.message;

          if (!this.alertShown && !ui.internal) {
            this.presentAlert();
          }
        }

        if (ui.loading) {
          this.isLoading = true;
        } else {
          this.isLoading = false;
        }

        this.updateService.checkUpdates();
      });

      const urlParams = new URLSearchParams(window.location.search);

      if (urlParams.get("systemDate") != null) {
        this.systemDateHandler({ systemDate: urlParams.get("systemDate") });
      }
    });
  }

  ngOnDestroy(): void {
    if (this.combinedStatesSubscription$) {
      this.combinedStatesSubscription$.unsubscribe();
    }
  }

  async presentAlert() {
    this.alertShown = true;
    const alertOptions: any = {
      header: this.alertTitle,
      subHeader: this.getAlertSubHeader(),
      message: this.getAlertMessage(),
      cssClass: this.getAlertCss(),
      backdropDismiss: false,
      buttons: this.getAlertButtons(),
    };
    if (this.supportFile) {
      alertOptions.inputs = [
        {
          name: "commentSection",
          type: "textarea",
          placeholder: this.translateService.instant("CORE.MESSAGES.SUPPORT_FILE_INPUT_PLACEHOLDER"),
          attributes: {
            minlength: 0,
            maxlength: 255,
          },
        },
      ];
    }
    const alert = await this.alertController.create(alertOptions);
    await alert.present();
  }

  private getAlertCss(): string[] {
    let css: string[] = ["alert-app-messages"];

    switch (this.messageType) {
      case EMessageType.INFORMATION:
        css.push("alert-info-message");
        break;
      case EMessageType.WARNING:
        css.push("alert-warning-message");
        break;
      case EMessageType.ERROR:
        css.push("alert-error-message");
        break;
      case EMessageType.UPDATE:
        css.push("alert-info-message");
        break;
    }

    return css;
  }

  getAlertSubHeader(): string {
    let subHeader = "";

    if (this.supportFile) {
      subHeader = this.alertMessage;
    }

    if (this.messageType == EMessageType.UPDATE) {
      subHeader = this.translateService.instant("CORE.MESSAGES.NEW_UPDATE");
    }

    return subHeader;
  }

  private getAlertMessage(): string {
    let message = this.alertMessage;

    if (this.supportFile) {
      message = this.translateService.instant("CORE.MESSAGES.NOTE");
    }

    if (this.messageType == EMessageType.UPDATE) {
      message = this.translateService.instant("CORE.MESSAGES.NOTE_UPDATE");
    }

    return message;
  }

  private getAlertButtons(): AlertButton[] {
    let alertButtons: AlertButton[] = [
      {
        text: this.translateService.instant("CORE.ACTIONS.OK"),
        role: "confirm",
        handler: () => {
          this.alertShown = false;
          this.store.dispatch(new UIActions.ResetMessage());
        },
      },
    ];

    if (this.supportFile) {
      alertButtons = [
        {
          text: this.translateService.instant("CORE.ACTIONS.CANCEL"),
          role: "cancel",
          handler: () => {
            this.alertShown = false;
            this.store.dispatch(new UIActions.ResetMessage());
          },
        },
        {
          text: this.translateService.instant("CORE.ACTIONS.SEND"),
          role: "confirm",
          handler: (data: any) => {
            const enteredText = data.commentSection;
            this.alertShown = false;
            this.store.dispatch(new UIActions.ResetMessage());
            this.store.dispatch(settingsActions.sendLogsToServer(enteredText));
          },
        },
      ];
    }

    if (this.messageType == EMessageType.UPDATE) {
      alertButtons = [
        {
          text: this.translateService.instant("CORE.ACTIONS.UPDATE"),
          role: "confirm",
          handler: () => {
            this.alertShown = false;
            this.store.dispatch(new UIActions.ResetMessage());
            window.location.reload();
          },
        },
      ];
    }

    return alertButtons;
  }

  private systemDateHandler(params: { systemDate: string | null }): void {
    setTimeout(() => {
      if (params["systemDate"]) {
        const systemDate = new Date(params["systemDate"]);
        if (isNaN(systemDate.getTime())) {
          this.store.dispatch(
            new UIActions.Error({
              error: `Invalid system date value "${params["systemDate"]}" in router query param`,
              internal: false,
            })
          );
        } else {
          if (systemDate.getFullYear() < 2020) {
            systemDate.setFullYear(2020);
          }
          this.logger.debug(`Set new system date with router query param as ${systemDate.toISOString()}`);
          let unixDate = getUnixTime(systemDate);
          this.store.dispatch(settingsActions.useCustomTime({ useCustomTime: true }));
          this.store.dispatch(timeActions.setCustomTime({ unixDate }));
          this.removeQueryParams();
        }
      }
    }, 1000);
  }

  private appIsBackground() {
    if (document.hidden) {
      this.store.dispatch(new UIActions.ShowSplash());
      this.isBackground = true;
    } else {
      if (this.isBackground) {
        this.isBackground = false;
        this.store.dispatch(new UIActions.StopSplash());
        this.store.dispatch(timeActions.fetchTime());
      }
    }
  }

  private removeQueryParams() {
    // Get current URL
    const urlTree = this.router.parseUrl(this.router.url);
    // Remove query parameters
    urlTree.queryParams = {};
    // Navigate to the new URL without query parameters
    this.router.navigateByUrl(urlTree.toString());
  }
}
