import { Component, Input, OnInit, OnChanges, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { format, isAfter, isBefore, isSameMinute, setHours } from "date-fns";
import { IWorkRecord } from "src/app/core/interfaces/work-record.interface";
import { Employee } from "src/app/core/models/employee.model";
import { Person } from "src/app/core/models/person.model";
import { WorkRecord } from "src/app/core/models/work-record.model";
import { IAppState } from "src/app/core/store/app/app.reducer";
import { WorkRecordsState } from "src/app/core/store/workRecords/workRecords.reducer";
import * as WorkRecordActions from "src/app/core/store/workRecords/workRecords.actions";
import * as UiActions from "src/app/core/store/ui/ui.actions";
import { EWorkRecordNotified, EWorkRecordStatus } from "src/app/core/enums/workRecord-enums";
import { Admin } from "src/app/core/models/admin.model";
import { Accounts } from "src/app/core/interfaces/account.types";
import { SharedService } from "../../services/shared.service";
import { EMessageType } from "src/app/core/enums/message-type.enum";
import { TranslateService } from "@ngx-translate/core";
import { NGXLogger } from "ngx-logger";
import { Board } from "src/app/core/models/board.model";
import { EmployeeService } from "../../services/employee.service";

@Component({
  selector: "app-hours-overview",
  templateUrl: "./hours-overview.component.html",
  styleUrls: ["./hours-overview.component.scss"],
})
export class HoursOverviewComponent implements OnInit, OnChanges {
  @Input() account!: Person | Employee | Board | undefined;
  @Input() alias!: Accounts | undefined;
  @Input() workRecordState!: WorkRecordsState;
  @Input() interval!: number;
  public disableButtonStart = false;
  public disableButtonNew = false;
  public startedWorkRecord: WorkRecord | undefined;
  public startTime: String = "";
  public isHoursToday: number = 0;
  public isHoursWeek: number = 0;
  public isHoursMonths: number = 0;
  public shouldHoursToday: number = 0;
  public shouldHoursWeek: number = 0;
  public shouldHoursMonths: number = 0;
  public canStartWorkRecords: boolean = false;
  public workRecordStartDate: string = "";

  constructor(
    private logger: NGXLogger,
    private translateService: TranslateService,
    private router: Router,
    private store: Store<IAppState>,
    private sharedService: SharedService,
    private employeeService: EmployeeService
  ) {}

  ngOnInit() {
    this.logger.info("OnInit: Initialize hours overview component");
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      this.handleInput();
    }
  }

  handleInput() {
    this.isHoursToday = this.employeeService.isHoursToday(this.sharedService.serverTime, this.workRecordState.workRecords);

    this.isHoursWeek = this.employeeService.isHoursWeek(this.sharedService.serverTime, this.workRecordState.workRecords);

    this.isHoursMonths = this.employeeService.isHoursMonth(this.sharedService.serverTime, this.workRecordState.workRecords);

    if (this.account instanceof Employee) {
      this.canStartWorkRecords = this.employeeService.canStartWorkRecords(this.account.workTimeSchemes);

      if (!this.canStartWorkRecords) {
        this.workRecordStartDate = format(this.employeeService.getWorkRecordsStartDate(this.account.workTimeSchemes), "dd.MM.yyyy");
      }

      this.disableButtonStart = !this.employeeService.isWorkingDay();

      if (!isBefore(this.sharedService.serverTime, setHours(this.sharedService.serverTime, 7))) {
        this.startedWorkRecord = this.employeeService.checkStartedWorkRecord(
          this.sharedService.serverTime,
          this.workRecordState.workRecords
        );
      }

      if ((this.sharedService.serverTime.getHours() < 7 || this.sharedService.serverTime.getHours() > 19) && !this.startedWorkRecord) {
        this.disableButtonStart = true;
      }

      this.shouldHoursToday = this.employeeService.shouldHoursToday(this.account, this.sharedService.serverTime);

      this.shouldHoursWeek = this.employeeService.shouldHoursWeek(this.account, this.sharedService.serverTime);

      this.shouldHoursMonths = this.employeeService.shouldHoursMonths(this.account, this.sharedService.serverTime);
    }

    if ((this.account instanceof Board || this.account instanceof Admin) && this.alias && this.alias instanceof Employee) {
      this.canStartWorkRecords = this.employeeService.canStartWorkRecords(this.alias.workTimeSchemes);

      if (!this.canStartWorkRecords) {
        this.workRecordStartDate = format(this.employeeService.getWorkRecordsStartDate(this.alias.workTimeSchemes), "dd.MM.yyyy");
      }

      this.disableButtonStart = true;

      if (
        !isBefore(this.sharedService.serverTime, setHours(this.sharedService.serverTime, 7)) &&
        !isAfter(this.sharedService.serverTime, setHours(this.sharedService.serverTime, 21))
      ) {
        this.startedWorkRecord = this.employeeService.checkStartedWorkRecord(
          this.sharedService.serverTime,
          this.workRecordState.workRecords
        );
      }

      this.shouldHoursToday = this.employeeService.shouldHoursToday(this.alias, this.sharedService.serverTime);

      this.shouldHoursWeek = this.employeeService.shouldHoursWeek(this.alias, this.sharedService.serverTime);

      this.shouldHoursMonths = this.employeeService.shouldHoursMonths(this.alias, this.sharedService.serverTime);
    }

    if (this.startedWorkRecord) {
      const hours = String(this.startedWorkRecord.startTime.getHours()).padStart(2, "0");
      const minutes = String(this.startedWorkRecord.startTime.getMinutes()).padStart(2, "0");
      const formattedTime = hours + ":" + minutes;
      this.startTime =
        this.translateService.instant("PAGES.ABSENT_RECORD.SINCE") +
        " " +
        formattedTime +
        " " +
        this.translateService.instant("PAGES.ABSENT_RECORD.CLOCK");
    }
  }

  openWorkRecordsOverview() {
    this.logger.info("Click: open work record monthly page");
    this.router.navigate(["app/zeitkonto/arbeitszeiten"]);
  }

  openWorkRecords() {
    this.logger.info("Click: Create new work record");
    if (!this.disableButtonNew) {
      this.router.navigate(["app/zeitkonto/arbeitszeiten/neu"]);
    }
  }

  startWorkRecord() {
    this.logger.info("Click: Start new work record");
    if (!this.disableButtonStart) {
      const date: Date = new Date(this.sharedService.serverTime);
      date.setSeconds(0);

      const w: IWorkRecord = {
        id: this.sharedService.generateGUID(),
        uuid: this.account?.userId!,
        editorId: "",
        startTime: date,
        endTime: undefined,
        comment: "",
        commentEditor: "",
        notified: EWorkRecordNotified.NOTIFIED,
        status: EWorkRecordStatus.CREATED_BY_EMPLOYEE_AUTOMATICALLY,
        editor: null,
      };

      const wr: WorkRecord = new WorkRecord(w);
      this.store.dispatch(new WorkRecordActions.AddWorkRecord({ workRecord: wr, navigate: false }));
    } else {
      this.store.dispatch(
        new UiActions.Message({
          message: this.translateService.instant("PAGES.ABSENT_RECORD.WORKDAYS_ONLY"),
          type: EMessageType.INFORMATION,
        })
      );
    }
  }

  stopWorkRecord() {
    this.logger.info("Click: Stop work record");
    if (!this.disableButtonStart) {
      const date: Date = this.sharedService.serverTime;
      date.setSeconds(0);

      if (isSameMinute(this.startedWorkRecord?.startTime!, date)) {
        this.store.dispatch(
          new WorkRecordActions.DeleteWorkRecord({
            workRecord: this.startedWorkRecord!,
            navigate: false,
          })
        );
      } else {
        const w: IWorkRecord = {
          id: this.startedWorkRecord?.id!,
          uuid: this.startedWorkRecord?.uuid!,
          editorId: "",
          startTime: this.startedWorkRecord?.startTime!,
          endTime: date,
          comment: this.startedWorkRecord?.comment ? this.startedWorkRecord?.comment : "",
          commentEditor: this.startedWorkRecord?.commentEditor ? this.startedWorkRecord?.commentEditor : "",
          notified: this.startedWorkRecord?.notified!,
          status: EWorkRecordStatus.CREATED_BY_EMPLOYEE_AUTOMATICALLY,
          editor: null,
        };
        const wr: WorkRecord = new WorkRecord(w);
        this.store.dispatch(
          new WorkRecordActions.UpdateWorkRecord({
            workRecord: wr,
            navigate: false,
          })
        );
      }
    }
  }
}
