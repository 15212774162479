import { format, isSameDay } from "date-fns";
import { EAbsenceNotified, EAbsenceStatus, EAbsencesType } from "../enums/absences.enum";
import { IAbsence } from "../interfaces/absence.interface";
import { de } from "date-fns/locale";

export class Absence implements IAbsence {
  private _id: string;
  private _uuid: string;
  private _editorId: string;
  private _startDate: Date;
  private _endDate: Date;
  private _comment: string | null | undefined;
  private _commentEditor: string | null | undefined;
  private _type: EAbsencesType;
  private _notified: EAbsenceNotified;
  private _status: EAbsenceStatus;
  private _employee:
    | {
        firstName: string;
        lastName: string;
      }
    | null
    | undefined;
  private _editor?:
    | {
        firstName: string;
        lastName: string;
      }
    | null
    | undefined;

  constructor(absence: IAbsence) {
    this._id = absence.id;
    this._uuid = absence.uuid;
    this._editorId = absence.editorId;
    this._startDate = new Date(absence.startDate);
    this._endDate = new Date(absence.endDate);
    this._comment = absence.comment;
    this._commentEditor = absence.commentEditor;
    this._type = absence.type;
    this._notified = absence.notified;
    this._status = absence.status;
    this._employee = absence.employee;
    this._editor = absence.editor;
  }

  get id(): string {
    return this._id;
  }

  set id(id: string) {
    this._id = id;
  }

  get uuid(): string {
    return this._uuid;
  }

  set uuid(uuid: string) {
    this._uuid = uuid;
  }

  get editorId(): string {
    return this._editorId;
  }

  set editorId(id: string) {
    this._editorId = id;
  }

  get startDate(): Date {
    return this._startDate;
  }

  set startDate(startDate: Date) {
    this._startDate = startDate;
  }

  get endDate(): Date {
    return this._endDate;
  }

  set endDate(endDate: Date) {
    this._endDate = endDate;
  }

  get comment(): string | null | undefined {
    return this._comment;
  }

  set comment(comment: string | null | undefined) {
    this._comment = comment;
  }

  get commentEditor(): string | null | undefined {
    return this._commentEditor;
  }

  set commentEditor(comment: string | null | undefined) {
    this._commentEditor = comment;
  }

  get type(): EAbsencesType {
    return this._type;
  }

  set type(type: EAbsencesType) {
    this._type = type;
  }

  get notified(): EAbsenceNotified {
    return this._notified;
  }

  set notified(notify: EAbsenceNotified) {
    this._notified = notify;
  }

  get status(): EAbsenceStatus {
    return this._status;
  }

  set status(status: EAbsenceStatus) {
    this._status = status;
  }

  get editor() {
    return this._editor;
  }

  set editor(editor) {
    this._editor = editor;
  }

  get employee() {
    return this._employee;
  }

  set employee(employee) {
    this._employee = employee;
  }

  getEmployeeName(): string {
    if (!this._employee) {
      return "";
    }

    return this._employee.firstName + " " + this._employee.lastName;
  }

  getEditorName(): string {
    if (!this._editor) {
      return "";
    }

    return this._editor.firstName + " " + this._editor.lastName;
  }
}
