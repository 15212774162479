import { Injectable } from "@angular/core";
import { SwUpdate, VersionReadyEvent } from "@angular/service-worker";
import { AppState } from "@capacitor/app";
import { Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { NGXLogger } from "ngx-logger";
import { filter, map } from "rxjs";
import { EMessageType } from "src/app/core/enums/message-type.enum";
import * as UIActions from "src/app/core/store/ui/ui.actions";

@Injectable({
  providedIn: "root",
})
export class UpdateService {
  constructor(private swUpdate: SwUpdate, private store: Store<AppState>, private platform: Platform, private logger: NGXLogger) {}

  checkUpdates() {
    if (this.platform.is("pwa")) {
      this.swUpdate.versionUpdates
        .pipe(
          filter((evt): evt is VersionReadyEvent => evt.type === "VERSION_READY"),
          map((evt) => ({
            type: "UPDATE_AVAILABLE",
            current: evt.currentVersion,
            available: evt.latestVersion,
          }))
        )
        .subscribe((evt) => {
          this.logger.info(`New pwa version found: Current version is ${evt.current.hash} and available version is ${evt.available.hash}`);
          this.store.dispatch(new UIActions.Message({ message: "PWA_UPDATE", type: EMessageType.UPDATE }));
        });
    }
  }
}
