import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { IAppState } from "src/app/core/store/app/app.reducer";
import { IWorkTimeScheme } from "src/app/core/interfaces/work-time-scheme.interface";
import { Employee } from "src/app/core/models/employee.model";
import { addDays, endOfMonth, isSameDay, startOfMonth } from "date-fns";
import { WorkRecord } from "src/app/core/models/work-record.model";
import { Accounts } from "src/app/core/interfaces/account.types";
import { IGenericPerson } from "src/app/core/interfaces/generic-person.interface";
import { AuthData } from "src/app/core/models/auth-data.model";
import { IAuthData } from "src/app/core/interfaces/auth-response.interface";
import { EUserStatus } from "src/app/core/enums/user-status.enum";
import { EPasswordReset } from "src/app/core/enums/password-reset.enum";
import { IWorkRecord } from "src/app/core/interfaces/work-record.interface";
import { EUserRole } from "src/app/core/enums/role.enum";
import { Parent } from "src/app/core/models/parent.model";
import { Admin } from "src/app/core/models/admin.model";
import { Observable, Subject, combineLatest } from "rxjs";
import { Absence } from "src/app/core/models/absence.model";
import { IAbsence } from "src/app/core/interfaces/absence.interface";
import {
  INotificationAbsence,
  INotificationWorkRecord,
  INotifications,
  NotificationTypes,
} from "src/app/core/interfaces/notification.interface";
import { NotificationAbsence } from "src/app/core/models/notification-absence.model";
import { NotificationWorkRecord } from "src/app/core/models/notification-workRecord.model";
import { Device } from "@capacitor/device";
import { Platform } from "@ionic/angular";
import { Board } from "src/app/core/models/board.model";
import { selectSystemTime } from "src/app/core/store/time/reducer";
import { EmployeeService } from "./employee.service";
import * as UIActions from "src/app/core/store/ui/ui.actions";
import { selectLanguage } from "src/app/core/store/settings/reducer";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  private _serverTime!: Date;
  private _serverTime$ = new Subject<Date>();
  public deviceID: any;
  public deviceInfo: any;
  public deviceBatteryInfo: any;
  public deviceLanguageCode: any;
  public languageCode: string = "";
  private combinedElements$!: Observable<[any]>;

  constructor(private store: Store<IAppState>, private platform: Platform, private employeeService: EmployeeService) {
    this.platform.ready().then(() => {
      this.getDeviceID().then((val) => {
        this.deviceID = val;
      });

      this.getDeviceInfo().then((val) => {
        this.deviceInfo = val;
      });

      this.getBatteryInfo().then((val) => {
        this.deviceBatteryInfo = val;
      });

      this.getDeviceLanguageCode().then((val) => {
        this.deviceLanguageCode = val;
      });
    });

    this.combinedElements$ = combineLatest([this.store.select(selectLanguage)]);

    this.combinedElements$.subscribe(([language]: [any]) => {
      this.languageCode = language;
    });

    this.store.select(selectSystemTime).subscribe((data: Date) => {
      this._serverTime = data;
      this._serverTime$.next(data);
    });
  }

  get serverTime(): Date {
    return new Date(this._serverTime);
  }

  getServerTime(): Observable<Date> {
    return this._serverTime$.asObservable();
  }

  generateGUID(): string {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  createUserFromInterface(account: IGenericPerson): Accounts {
    var user: any;
    switch (account.role) {
      case EUserRole.ADMINISTRATOR: {
        user = new Admin(account);
        break;
      }
      case EUserRole.BOARD: {
        user = new Board(account);
        break;
      }
      case EUserRole.MANAGER:
      case EUserRole.EMPLOYEE:
      case EUserRole.TRAINEE: {
        user = new Employee(account);
        break;
      }
      default: {
        user = new Parent(account);
      }
    }
    return user;
  }

  createUserFromAPI(data: any) {
    const genericUser: IGenericPerson = {
      userId: data.id.toString(),
      firstName: data.firstName,
      lastName: data.lastName,
      gender: data.gender,
      role: data.role,
      email: data.email,
      phone: data.phone,
      address: data.address,
      houseNr: data.houseNr,
      zipCode: data.zipCode,
      city: data.city,
      entryDate: data.entryDate ? this.convertUTCDateTimeToLocal(data.entryDate) : undefined,
      exitDate: data.exitDate ? this.convertUTCDateTimeToLocal(data.exitDate) : undefined,
      status: data.status,
      passwordReset: data.passwordReset,
    };

    if (data.work_time_schemes && data.work_time_schemes.length > 0) {
      const workTimeSchemes: IWorkTimeScheme[] = this.createWorkTimeSchemesFromAPI(data.work_time_schemes);
      genericUser.workTimeSchemes = workTimeSchemes;
    }

    if (data.absences && data.absences.length > 0) {
      const absences: Absence[] = [];

      data.absences.forEach((absence: any) => {
        absences.push(this.createAbsenceFromResponse(absence));
      });

      genericUser.absences = absences;
    }

    if (data.work_records && data.work_records.length > 0) {
      const workRecords: WorkRecord[] = [];

      data.work_records.forEach((wr: any) => {
        workRecords.push(this.createWorkRecordFromResponse(wr));
      });

      genericUser.workRecords = workRecords;
    }

    const user = this.createUserFromInterface(genericUser);

    return user;
  }

  createWorkRecordFromResponse(workRecord: any): WorkRecord {
    const workRecordFromResponse: IWorkRecord = {
      id: workRecord.id.toString(),
      uuid: workRecord.employee_id.toString(),
      editorId: workRecord.editor_id ? workRecord.editor_id.toString() : "",
      startTime: this.convertUTCDateTimeToLocal(workRecord.start_time),
      endTime: workRecord.end_time ? this.convertUTCDateTimeToLocal(workRecord.end_time) : undefined,
      comment: workRecord.comment ? workRecord.comment : undefined,
      commentEditor: workRecord.comment_editor ? workRecord.comment_editor : undefined,
      notified: workRecord.notified,
      status: workRecord.status,
      editor: workRecord.editor ? { firstName: workRecord.editor.firstName, lastName: workRecord.editor.lastName } : undefined,
    };

    var wR: WorkRecord;
    wR = new WorkRecord(workRecordFromResponse);
    return wR;
  }

  createNotificationsFromResponse(response: any): INotifications {
    const NOTIFICATIONS: INotifications = {
      absences: [],
      workRecords: [],
    };

    if (response.absences.length > 0) {
      for (let notification of response.absences) {
        let tmpAbsenceNotification: INotificationAbsence = {
          id: notification.id,
          uuid: notification.author_id,
          status: notification.status,
          recipient: notification.recipient_id,
          absenceId: notification.absence_id,
          absenceNotificationType: notification.absence_notification_type,
          absenceType: notification.absence_type,
          author: { firstName: notification.author.firstName, lastName: notification.author.lastName },
          created: this.convertUTCDateTimeToLocal(notification.created_at),
          startDate: this.convertUTCDateTimeToLocal(notification.start_date),
          endDate: this.convertUTCDateTimeToLocal(notification.end_date),
        };

        NOTIFICATIONS.absences.push(new NotificationAbsence(tmpAbsenceNotification));
      }
    }

    if (response.workrecords.length > 0) {
      for (let notification of response.workrecords) {
        let tmpAbsenceNotification: INotificationWorkRecord = {
          id: notification.id,
          uuid: notification.author_id,
          status: notification.status,
          recipient: notification.recipient_id,
          workRecordId: notification.workrecord_id,
          workRecordType: notification.workrecord_type,
          author: { firstName: notification.author.firstName, lastName: notification.author.lastName },
          created: this.convertUTCDateTimeToLocal(notification.created_at),
          workRecordDate: this.convertUTCDateTimeToLocal(notification.workrecord_date),
        };

        NOTIFICATIONS.workRecords.push(new NotificationWorkRecord(tmpAbsenceNotification));
      }
    }
    return NOTIFICATIONS;
  }

  createAbsenceFromResponse(absence: any): Absence {
    const absenceFromResponse: IAbsence = {
      id: absence.id.toString(),
      uuid: absence.employee_id.toString(),
      editorId: absence.editor_id ? absence.editor_id.toString() : "",
      startDate: this.convertUTCDateTimeToLocal(absence.start_date),
      endDate: this.convertUTCDateTimeToLocal(absence.end_date),
      comment: absence.comment ? absence.comment : undefined,
      commentEditor: absence.comment_editor ? absence.comment_editor : undefined,
      type: absence.type,
      notified: absence.notified,
      status: absence.status,
      employee: absence.employee ? { firstName: absence.employee.firstName, lastName: absence.employee.lastName } : undefined,
      editor: absence.editor ? { firstName: absence.editor.firstName, lastName: absence.editor.lastName } : undefined,
    };

    var tmpAbsence: Absence;
    tmpAbsence = new Absence(absenceFromResponse);
    return tmpAbsence;
  }

  createEmptyGenericPeople(): IGenericPerson {
    var person: IGenericPerson = {
      userId: this.generateGUID(),
      lastName: "",
      firstName: "",
      gender: undefined,
      role: undefined,
      email: "",
      phone: "",
      address: "",
      houseNr: "",
      zipCode: "",
      city: "",
      entryDate: this.employeeService.getWorkingDay(new Date(this._serverTime)),
      status: EUserStatus.PENDING,
      passwordReset: EPasswordReset.CUSTOM,
      exitDate: undefined,
      workTimeSchemes: [],
    };

    return person;
  }

  createAuthDataForStore(authData: AuthData): IAuthData {
    const data: IAuthData = {
      tokenType: authData.tokenType,
      expiresIn: authData.expiresIn,
      accessToken: authData.accessToken,
      refreshToken: authData.refreshToken,
      tokenExpirationDate: authData.tokenExpirationDate,
    };
    return data;
  }

  createAuthDataFromInterface(authData: any): AuthData {
    const data = new AuthData({
      tokenType: authData.tokenType,
      expiresIn: authData.expiresIn,
      accessToken: authData.accessToken,
      refreshToken: authData.refreshToken,
      tokenExpirationDate: this.convertUTCDateTimeToLocal(authData.tokenExpirationDate),
    });
    return data;
  }

  createNotificationFromInterface(notification: NotificationTypes): NotificationTypes {
    let newNotification!: NotificationTypes;
    if (notification instanceof NotificationAbsence) {
      const tmp: INotificationAbsence = {
        id: notification.id,
        uuid: notification.uuid,
        status: notification.status,
        recipient: notification.recipient,
        created: notification.created,
        author: notification.author,
        absenceId: notification.absenceId,
        absenceNotificationType: notification.absenceNotificationType,
        absenceType: notification.absenceType,
        startDate: notification.startDate,
        endDate: notification.endDate,
      };

      newNotification = new NotificationAbsence(tmp);
    }

    if (notification instanceof NotificationWorkRecord) {
      const tmp: INotificationWorkRecord = {
        id: notification.id,
        uuid: notification.uuid,
        status: notification.status,
        recipient: notification.recipient,
        created: notification.created,
        author: notification.author,
        workRecordId: notification.workRecordId,
        workRecordType: notification.workRecordType,
        workRecordDate: notification.workRecordDate,
      };

      newNotification = new NotificationWorkRecord(tmp);
    }

    return newNotification;
  }

  createUserInterfaceFromModel(account: Accounts): IGenericPerson {
    var user: IGenericPerson = {
      userId: account.userId,
      lastName: account.lastName,
      firstName: account.firstName,
      gender: account.gender,
      role: account.role,
      email: account.email,
      phone: account.phone,
      address: account.address,
      houseNr: account.houseNr,
      zipCode: account.zipCode,
      city: account.city,
      entryDate: account.entryDate,
      status: account.status,
      passwordReset: account.passwordReset,
      exitDate: account.exitDate ? account.exitDate : undefined,
    };

    if (account instanceof Employee) {
      user.workTimeSchemes = account.workTimeSchemes;
    }

    return user;
  }

  getAllDaysOfMonth(date: Date): Date[] {
    const DAYS_OF_MONTH: Date[] = [];

    let startDay = startOfMonth(date);
    let endDay = endOfMonth(date);

    while (!isSameDay(startDay, endDay)) {
      DAYS_OF_MONTH.push(new Date(startDay));
      startDay = addDays(startDay, 1);
    }

    DAYS_OF_MONTH.push(endDay);

    return DAYS_OF_MONTH;
  }

  getLanguageCodeDateTime(): string {
    let code = "";

    switch (this.languageCode) {
      case "de":
        code = "de-DE";
        break;
      case "en":
        code = "en-GB";
        break;
      case "tr":
        code = "tr-TR";
        break;
    }

    return code;
  }

  getLocalizedMonthAndYear(date: Date, withYear = true): string {
    let monthName = date.toLocaleString(this.languageCode, { month: "long" });
    let year = date.getFullYear();
    let monthNameAndYear = withYear ? monthName + " " + year : monthName;
    return monthNameAndYear;
  }

  getLocalizedDateWithDayName(date: Date): string {
    var options = { weekday: "long", year: "numeric", month: "2-digit", day: "2-digit" } as const;
    return date.toLocaleDateString(this.languageCode, options);
  }

  getLocalizedDayName(weekday: number, format: any): { day: string; weekday: number } {
    let date = new Date(this._serverTime);

    while (date.getDay() !== weekday) {
      date.setDate(date.getDate() + 1);
    }
    let dayName = {
      day: date.toLocaleDateString(this.getLanguageCodeDateTime(), { weekday: format }),
      weekday: date.getDay(),
    };
    return dayName;
  }

  convertUTCDateTimeToLocal(utcTime: string) {
    var date;
    if (utcTime.includes("Z")) {
      date = new Date(utcTime);
    } else {
      date = new Date(utcTime + " UTC");
    }
    return date;
  }

  getDateTimAsIso(date: Date): string {
    const isoDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
    return isoDate;
  }

  private createWorkTimeSchemesFromAPI(data: any[]): IWorkTimeScheme[] {
    const workTimeSchemes: IWorkTimeScheme[] = [];

    data.forEach((d, index) => {
      const workTimeScheme: IWorkTimeScheme = {
        id: d.id.toString(),
        uuid: d.employee_id.toString(),
        startDate: this.convertUTCDateTimeToLocal(d.start_date),
        endDate: d.end_date ? this.convertUTCDateTimeToLocal(d.end_date) : undefined,
        startTime: d.start_time ? d.start_time : null,
        endTime: d.end_time ? d.end_time : null,
        workingHours: d.working_hours,
        vacationDays: d.vacation_days,
        carryover_vacations: d.carryover_vacations,
        carryover_overtime: d.carryover_overtime,
        workingDays: {
          monday: d.monday,
          tuesday: d.tuesday,
          wednesday: d.wednesday,
          thursday: d.thursday,
          friday: d.friday,
        },
      };

      workTimeSchemes.push(workTimeScheme);
    });

    return workTimeSchemes;
  }

  private async getDeviceID() {
    try {
      return await Device.getId();
    } catch (error) {
      this.store.dispatch(new UIActions.Error({ error: error, internal: true }));
      return undefined;
    }
  }

  private async getDeviceInfo() {
    try {
      return await Device.getInfo();
    } catch (error) {
      this.store.dispatch(new UIActions.Error({ error: error, internal: true }));
      return undefined;
    }
  }

  private async getBatteryInfo() {
    try {
      return await Device.getBatteryInfo();
    } catch (error) {
      this.store.dispatch(new UIActions.Error({ error: error, internal: true }));
      return undefined;
    }
  }

  private async getDeviceLanguageCode() {
    try {
      return await Device.getLanguageCode();
    } catch (error) {
      this.store.dispatch(new UIActions.Error({ error: error, internal: true }));
      return undefined;
    }
  }
}
