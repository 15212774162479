import { createFeature, createReducer, createSelector, on } from "@ngrx/store";
import { IUIState } from "./interfaces/ui.interface";
import { uiActions } from "./actions";

const initialState: IUIState = {
  apiFetch: {
    systemTime: false,
    personalData: false,
    openAbsences: false,
    notifications: false,
    employees: false,
    workRecords: false,
    absences: false,
  },
};

const uiFeature = createFeature({
  name: "uiState",
  reducer: createReducer(
    initialState,
    on(uiActions.fetchSystemTime, (state) => ({
      ...state,
      apiFetch: { ...state.apiFetch, systemTime: true },
    })),
    on(uiActions.successFetchingSystemTime, (state) => ({
      ...state,
      apiFetch: { ...state.apiFetch, systemTime: false },
    })),
    on(uiActions.fetchPersonalData, (state) => ({
      ...state,
      apiFetch: { ...state.apiFetch, personalData: true },
    })),
    on(uiActions.successFetchingPersonalData, (state) => ({
      ...state,
      apiFetch: { ...state.apiFetch, personalData: false },
    })),
    on(uiActions.fetchOpenAbsences, (state) => ({
      ...state,
      apiFetch: { ...state.apiFetch, openAbsences: true },
    })),
    on(uiActions.successFetchingOpenAbsences, (state) => ({
      ...state,
      apiFetch: { ...state.apiFetch, openAbsences: false },
    })),
    on(uiActions.fetchNotifications, (state) => ({
      ...state,
      apiFetch: { ...state.apiFetch, notifications: true },
    })),
    on(uiActions.successFetchingNotifications, (state) => ({
      ...state,
      apiFetch: { ...state.apiFetch, notifications: false },
    })),
    on(uiActions.fetchEmployees, (state) => ({
      ...state,
      apiFetch: { ...state.apiFetch, employees: true },
    })),
    on(uiActions.successFetchingEmployees, (state) => ({
      ...state,
      apiFetch: { ...state.apiFetch, employees: false },
    })),
    on(uiActions.fetchWorkRecords, (state) => ({
      ...state,
      apiFetch: { ...state.apiFetch, workRecords: true },
    })),
    on(uiActions.successFetchingWorkRecords, (state) => ({
      ...state,
      apiFetch: { ...state.apiFetch, workRecords: false },
    })),
    on(uiActions.fetchAbsences, (state) => ({
      ...state,
      apiFetch: { ...state.apiFetch, absences: true },
    })),
    on(uiActions.successFetchingAbsences, (state) => ({
      ...state,
      apiFetch: { ...state.apiFetch, absences: false },
    })),
    on(uiActions.failedFetching, (state) => ({
      ...state,
      apiFetch: {
        systemTime: false,
        personalData: false,
        openAbsences: false,
        notifications: false,
        employees: false,
        workRecords: false,
        absences: false,
      },
    }))
  ),
  extraSelectors: ({ selectApiFetch }) => ({
    selectUIFetchStatus: createSelector(selectApiFetch, (apiFetch) => Object.values(apiFetch).every((value) => value === false)),
  }),
});

export const { name: uiFeatureKey, reducer: uiFeatureReducer, selectApiFetch, selectUIFetchStatus } = uiFeature;
