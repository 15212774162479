import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { ITimeState } from "./interfaces/time.interface";

export const timeActions = createActionGroup({
  source: "SystemTime",
  events: {
    "Fetch time": emptyProps,
    "Fetch time success": props<{ dates: ITimeState }>(),
    "Set custom time": props<{ unixDate: number }>(),
    "Set custom time diff": props<{ timeDiff: number }>(),
    "Set custom success": emptyProps,
    "Update time": emptyProps,
    "Set time": props<{ date: Date }>(),
    "Reset custom time": emptyProps,
  },
});
