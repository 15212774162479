import { IAbsence } from "../interfaces/absence.interface";
import { IEmployee } from "../interfaces/employee.interface";
import { IGenericPerson } from "../interfaces/generic-person.interface";
import { IWorkTimeScheme } from "../interfaces/work-time-scheme.interface";
import { Absence } from "./absence.model";
import { Person } from "./person.model";
import { WorkRecord } from "./work-record.model";

export class Employee extends Person implements IEmployee {
  private _workTimeSchemes: IWorkTimeScheme[];
  private _absences: Absence[];

  private _workRecords: WorkRecord[];

  constructor(person: IGenericPerson) {
    super(person);
    this._workTimeSchemes = person.workTimeSchemes ? person.workTimeSchemes : [];
    this._absences = person.absences ? person.absences : [];
    this._workRecords = person.workRecords ? person.workRecords : [];
  }

  get workTimeSchemes(): IWorkTimeScheme[] {
    return this._workTimeSchemes;
  }

  set workTimeSchemes(value: IWorkTimeScheme[]) {
    this._workTimeSchemes = value;
  }

  get absences(): Absence[] {
    return this._absences;
  }

  set absences(value: Absence[]) {
    this._absences = value;
  }

  get workRecords(): WorkRecord[] {
    return this._workRecords;
  }

  set workRecords(value: WorkRecord[]) {
    this._workRecords = value;
  }
}
