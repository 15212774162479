import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  addDays,
  addWeeks,
  compareAsc,
  eachDayOfInterval,
  endOfWeek,
  format,
  formatISO,
  isAfter,
  isBefore,
  isSameDay,
  isSameMonth,
  isWeekend,
  isWithinInterval,
  setHours,
  setMinutes,
  setSeconds,
  startOfWeek,
} from "date-fns";
import { EAbsenceStatus, EAbsencesType } from "src/app/core/enums/absences.enum";
import { IAbsence } from "src/app/core/interfaces/absence.interface";
import { IEmployee } from "src/app/core/interfaces/employee.interface";
import { ITime } from "src/app/core/interfaces/time.interface";
import { IWorkRecord } from "src/app/core/interfaces/work-record.interface";
import { IWorkTimeScheme } from "src/app/core/interfaces/work-time-scheme.interface";
import { IAppState } from "src/app/core/store/app/app.reducer";
import { selectSystemTime } from "src/app/core/store/time/reducer";
import { EmployeePresenceTypes } from "src/app/features/overview/components/employee-presence/models/employee-presence-status.model";
import { Absence } from "src/app/core/models/absence.model";
import { Observable, combineLatest } from "rxjs";
import { de, enGB, tr } from "date-fns/locale";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { TranslateService } from "@ngx-translate/core";
import { Employee } from "src/app/core/models/employee.model";
import { WorkRecord } from "src/app/core/models/work-record.model";
import { IHoliday } from "src/app/core/interfaces/holiday.interface";
import { holidays } from "src/assets/static/holidays";
import { AbsencesState } from "src/app/core/store/absences/absences.reducer";
import { IEmployeePresence } from "src/app/core/store/users/interfaces/employee-presence.interface";
import { IGenericPerson } from "src/app/core/interfaces/generic-person.interface";
import { selectLanguage } from "src/app/core/store/settings/reducer";

@Injectable({
  providedIn: "root",
})
export class EmployeeService {
  systemTime: Date = new Date();
  public languageCode: string = "";
  private absences: Absence[] = [];
  private combinedElements$!: Observable<[any, AbsencesState]>;

  constructor(private store: Store<IAppState>, private translateService: TranslateService) {
    this.combinedElements$ = combineLatest([this.store.select(selectLanguage), this.store.select("absences")]);

    this.combinedElements$.subscribe(([language, absencesState]: [any, AbsencesState]) => {
      this.languageCode = language;
      this.absences = absencesState.absences;
    });

    this.store.select(selectSystemTime).subscribe((data) => {
      this.systemTime = data;
    });
  }

  public employeesFromApi(data: any[]): Employee[] {
    const employees: Employee[] = [];

    data.forEach((d, index) => {
      const employee: Employee = this.employeeFromApi(d);
      employees.push(employee);
    });

    employees.sort((a, b) => {
      return a.lastName.localeCompare(b.lastName);
    });

    return employees;
  }

  public employeeFromApi(data: any): Employee {
    const employee: IGenericPerson = {
      userId: data.id.toString(),
      lastName: data.lastName,
      firstName: data.firstName,
      gender: data.gender,
      role: data.role,
      email: data.email,
      phone: data.phone,
      address: data.address,
      houseNr: data.houseNr,
      zipCode: data.zipCode,
      city: data.city,
      entryDate: new Date(data.entryDate),
      exitDate: data.exitDate ? new Date(data.exitDate) : undefined,
      status: data.status,
      passwordReset: data.passwordReset,
      workTimeSchemes: [],
      absences: [],
      workRecords: [],
    };

    if (data.work_time_schemes && data.work_time_schemes.length > 0) {
      const workTimeSchemes: IWorkTimeScheme[] = this.workTimeSchemesFromApi(data.work_time_schemes);
      employee.workTimeSchemes = workTimeSchemes;
    }

    if (data.absences && data.absences.length > 0) {
      const absences: Absence[] = this.absencesFromApi(data.absences);
      employee.absences = absences;
    }

    if (data.work_records && data.work_records.length > 0) {
      const workRecords: WorkRecord[] = this.workRecordsFromApi(data.work_records);
      employee.workRecords = workRecords;
    }

    let em = new Employee(employee);

    return em;
  }

  public workRecordsFromApi(data: any[]): WorkRecord[] {
    const workRecords: WorkRecord[] = [];

    data.forEach((d, index) => {
      const workRecord: WorkRecord = this.workRecordFromApi(d);
      workRecords.push(workRecord);
    });

    return workRecords;
  }

  public workRecordFromApi(data: any): WorkRecord {
    const workRecord: IWorkRecord = {
      id: data.id.toString(),
      uuid: data.employee_id.toString(),
      editorId: data.editor_id ? data.editor_id.toString() : "",
      startTime: new Date(data.start_time),
      endTime: data.end_time ? new Date(data.end_time) : null,
      comment: data.comment ? data.comment : null,
      commentEditor: data.comment_editor ? data.comment_editor : null,
      notified: data.notified,
      status: data.status,
      editor: data.editor ? { firstName: data.editor.firstName, lastName: data.editor.lastName } : null,
    };

    let wR = new WorkRecord(workRecord);

    return wR;
  }

  public absencesFromApi(data: any[]): Absence[] {
    const absences: Absence[] = [];

    data.forEach((d, index) => {
      const absence: Absence = this.absenceFromApi(d);
      absences.push(absence);
    });

    return absences;
  }

  public absenceFromApi(data: any): Absence {
    const absence: IAbsence = {
      id: data.id.toString(),
      uuid: data.employee_id.toString(),
      editorId: data.editor_id ? data.editor_id.toString() : "",
      startDate: new Date(data.start_date),
      endDate: new Date(data.end_date),
      comment: data.comment ? data.comment : undefined,
      commentEditor: data.comment_editor ? data.comment_editor : null,
      type: data.type,
      notified: data.notified,
      status: data.status,
      employee: data.employee ? { firstName: data.employee.firstName, lastName: data.employee.lastName } : null,
      editor: data.editor ? { firstName: data.editor.firstName, lastName: data.editor.lastName } : null,
    };

    let a = new Absence(absence);

    return a;
  }

  public workTimeSchemesFromApi(data: any[]): IWorkTimeScheme[] {
    const workTimeSchemes: IWorkTimeScheme[] = [];

    data.forEach((d, index) => {
      const workTimeScheme: IWorkTimeScheme = {
        id: d.id.toString(),
        uuid: d.employee_id.toString(),
        startDate: new Date(d.start_date),
        endDate: d.end_date ? new Date(d.end_date) : null,
        startTime: d.start_time ? this.convertTimeFromApi(d.start_time) : null,
        endTime: d.end_time ? this.convertTimeFromApi(d.end_time) : null,
        workingHours: d.working_hours ? d.working_hours : null,
        vacationDays: d.vacation_days ? d.vacation_days : null,
        carryover_vacations: d.carryover_vacations ? d.carryover_vacations : null,
        carryover_overtime: d.carryover_overtime ? d.carryover_overtime : null,
        workingDays: {
          monday: d.monday,
          tuesday: d.tuesday,
          wednesday: d.wednesday,
          thursday: d.thursday,
          friday: d.friday,
        },
      };

      workTimeSchemes.push(workTimeScheme);
    });

    return workTimeSchemes;
  }

  employeePresenceStates(employees: Employee[]): EmployeePresenceTypes {
    let employeesTmp = [...employees];

    const EMPLOYEES_STATES: EmployeePresenceTypes = {
      online: null,
      offline: null,
      overdue: null,
      vacant: null,
      absence: null,
    };

    EMPLOYEES_STATES.absence = this.employeesOnAbsence(employeesTmp);

    if (EMPLOYEES_STATES.absence) {
      employeesTmp = employeesTmp.filter((employee) => !EMPLOYEES_STATES.absence?.some((obj) => obj.employee.userId === employee.userId));
    }

    EMPLOYEES_STATES.online = this.employeesOnline(employeesTmp);

    if (EMPLOYEES_STATES.online) {
      employeesTmp = employeesTmp.filter((employee) => !EMPLOYEES_STATES.online?.some((obj) => obj.employee.userId === employee.userId));
    }

    EMPLOYEES_STATES.vacant = this.employeesVacant(employeesTmp);

    if (EMPLOYEES_STATES.vacant) {
      employeesTmp = employeesTmp.filter((employee) => !EMPLOYEES_STATES.vacant?.some((obj) => obj.userId === employee.userId));
    }

    EMPLOYEES_STATES.overdue = this.employeesOverdue(employeesTmp);

    if (EMPLOYEES_STATES.overdue) {
      employeesTmp = employeesTmp.filter((employee) => !EMPLOYEES_STATES.overdue?.some((obj) => obj.employee.userId === employee.userId));
    }

    EMPLOYEES_STATES.offline = this.employeesOffline(employeesTmp);

    if (EMPLOYEES_STATES.offline) {
      employeesTmp = employeesTmp.filter((employee) => !EMPLOYEES_STATES.offline?.some((obj) => obj.employee.userId === employee.userId));
    }

    return EMPLOYEES_STATES;
  }

  employeesOnline(employees: Employee[]): IEmployeePresence[] | null {
    const employeeList: IEmployeePresence[] = [];
    const date = new Date(this.systemTime);

    employees.forEach((employee, index) => {
      if (employee.workRecords && this.openWorkRecords(employee.workRecords)) {
        let employeePresence: IEmployeePresence = {
          employee: employee,
          isHours: this.isHoursToday(date, employee.workRecords),
          shouldHours: this.shouldHoursToday(employee, date),
        };

        employeeList.push(employeePresence);
      }
    });

    return employeeList.length > 0 ? employeeList : null;
  }

  employeesOverdue(employees: Employee[]): IEmployeePresence[] | null {
    const employeeList: IEmployeePresence[] = [];
    const date = new Date(this.systemTime);

    employees.forEach((employee, index) => {
      const WORK_TIME_SCHEME = this.currentWorkTimeScheme(employee.workTimeSchemes);

      if (
        (!this.isDateHoliday(this.systemTime) &&
          !isWeekend(this.systemTime) &&
          WORK_TIME_SCHEME?.startTime &&
          this.compareDateWithTime(this.systemTime, WORK_TIME_SCHEME?.startTime) == 1 &&
          WORK_TIME_SCHEME?.endTime &&
          this.compareDateWithTime(this.systemTime, WORK_TIME_SCHEME?.endTime) == -1 &&
          !this.openWorkRecords(employee.workRecords)) ||
        (WORK_TIME_SCHEME?.endTime &&
          this.compareDateWithTime(this.systemTime, WORK_TIME_SCHEME?.endTime) == 1 &&
          employee.workRecords.length == 0)
      ) {
        if (WORK_TIME_SCHEME && this.isCurrentDayWorkDay(WORK_TIME_SCHEME)) {
          let employeePresence: IEmployeePresence = {
            employee: employee,
            isHours: this.isHoursToday(date, employee.workRecords),
            shouldHours: this.shouldHoursToday(employee, date),
          };
          employeeList.push(employeePresence);
        }
      }
    });

    return employeeList.length > 0 ? employeeList : null;
  }

  employeesOffline(employees: Employee[]): IEmployeePresence[] | null {
    const employeeList: IEmployeePresence[] = [];
    const date = new Date(this.systemTime);

    employees.forEach((employee, index) => {
      const WORK_TIME_SCHEME = this.currentWorkTimeScheme(employee.workTimeSchemes);

      if (
        !this.isDateHoliday(this.systemTime) &&
        !isWeekend(this.systemTime) &&
        ((WORK_TIME_SCHEME?.startTime && this.compareDateWithTime(this.systemTime, WORK_TIME_SCHEME?.startTime) == -1) ||
          (WORK_TIME_SCHEME?.endTime && this.compareDateWithTime(this.systemTime, WORK_TIME_SCHEME?.endTime) == 1))
      ) {
        if (this.isCurrentDayWorkDay(WORK_TIME_SCHEME)) {
          let employeePresence: IEmployeePresence = {
            employee: employee,
            isHours: this.isHoursToday(date, employee.workRecords),
            shouldHours: this.shouldHoursToday(employee, date),
          };
          employeeList.push(employeePresence);
        }
      }
    });

    return employeeList.length > 0 ? employeeList : null;
  }

  employeesVacant(employees: Employee[]): Employee[] | null {
    const employeeList: Employee[] = [];

    employees.forEach((employee, index) => {
      const WORK_TIME_SCHEME = this.currentWorkTimeScheme(employee.workTimeSchemes);
      const DAY = this.systemTime.getDay();

      const isEmployeeAdded = (employeeToCheck: Employee) => {
        return employeeList.some((e) => e.userId === employeeToCheck.userId);
      };

      if ((this.isDateHoliday(this.systemTime) || isWeekend(this.systemTime)) && employee.workRecords.length == 0) {
        if (!isEmployeeAdded(employee)) {
          employeeList.push(employee);
        }
      }

      switch (DAY) {
        case 1:
          if (!WORK_TIME_SCHEME?.workingDays.monday && !isEmployeeAdded(employee)) employeeList.push(employee);
          break;
        case 2:
          if (!WORK_TIME_SCHEME?.workingDays.tuesday && !isEmployeeAdded(employee)) employeeList.push(employee);
          break;
        case 3:
          if (!WORK_TIME_SCHEME?.workingDays.wednesday && !isEmployeeAdded(employee)) employeeList.push(employee);
          break;
        case 4:
          if (!WORK_TIME_SCHEME?.workingDays.thursday && !isEmployeeAdded(employee)) employeeList.push(employee);
          break;
        case 5:
          if (!WORK_TIME_SCHEME?.workingDays.friday && !isEmployeeAdded(employee)) employeeList.push(employee);
          break;
      }
    });

    return employeeList.length > 0 ? employeeList : null;
  }

  employeesOnAbsence(employees: Employee[]): { employee: Employee; absence: Absence }[] | null {
    const employeeList: { employee: Employee; absence: Absence }[] = [];

    employees.forEach((employee, index) => {
      let absence = this.isEmployeeOnAbsence(employee);
      if (absence) {
        employeeList.push({ employee, absence });
      }
    });

    return employeeList.length > 0 ? employeeList : null;
  }

  isEmployeeOnAbsence(employee: Employee): Absence | null {
    let absence: Absence | null | undefined = null;

    if (employee.absences.length == 0) {
      return null;
    }

    absence = employee.absences.find((absence) => isWithinInterval(this.systemTime, { start: absence.startDate, end: absence.endDate }));

    return absence ? absence : null;
  }

  currentWorkTimeScheme(wts: IWorkTimeScheme[], date: Date = this.systemTime): IWorkTimeScheme | null {
    let workTimeSchemes = [...wts];

    workTimeSchemes.sort((a, b) => a.startDate.getTime() - b.startDate.getTime());

    const CHECK_DATE = new Date(date);
    let currentWorkTimeScheme: IWorkTimeScheme | undefined = undefined;

    for (const item of workTimeSchemes) {
      // Check if the item's startDate is earlier or equal to today
      if (item.startDate && item.endDate && item.startDate <= CHECK_DATE && CHECK_DATE <= item.endDate) {
        currentWorkTimeScheme = item;
      }

      if (item.startDate && !item.endDate && item.startDate <= CHECK_DATE) {
        currentWorkTimeScheme = item;
      }
    }

    return currentWorkTimeScheme ? currentWorkTimeScheme : null;
  }

  isDateHoliday(date: Date): IHoliday | undefined {
    const holidays: IHoliday[] = this.getHolidays(date.getFullYear().toString());

    const holidayList = holidays.find((day) => day.date.getMonth() === date.getMonth() && day.date.getDate() === date.getDate());
    return holidayList;
  }

  isCurrentDayWorkDay(workTimeScheme: IWorkTimeScheme, date: Date = new Date(this.systemTime)): boolean {
    let isWorkDay = false;

    const DAY = date.getDay();

    switch (DAY) {
      case 1:
        if (workTimeScheme.workingDays.monday) isWorkDay = true;
        break;
      case 2:
        if (workTimeScheme.workingDays.tuesday) isWorkDay = true;
        break;
      case 3:
        if (workTimeScheme.workingDays.wednesday) isWorkDay = true;
        break;
      case 4:
        if (workTimeScheme.workingDays.thursday) isWorkDay = true;
        break;
      case 5:
        if (workTimeScheme.workingDays.friday) isWorkDay = true;
        break;
    }

    return isWorkDay;
  }

  getLanguageLocaleCode(): Locale {
    let code!: Locale;

    switch (this.languageCode) {
      case "de":
        code = de;
        break;
      case "en":
        code = enGB;
        break;
      case "tr":
        code = tr;
        break;
      default:
        code = de;
    }

    return code;
  }

  getAbsenceDateRange(absence: Absence): string {
    var date: string = "";

    const STARTDATE = format(absence.startDate, "EEE dd.MM.yy", { locale: this.getLanguageLocaleCode() });
    const ENDDATE = format(absence.endDate, "EEE dd.MM.yy", { locale: this.getLanguageLocaleCode() });

    if (isSameDay(absence.startDate, absence.endDate)) {
      return STARTDATE;
    }

    date = STARTDATE + " - " + ENDDATE;
    return date;
  }

  getAbsenceIconColor(type: EAbsencesType): string {
    var title = "";

    switch (type) {
      case 0:
        title = "app-success";
        break;
      case 1:
        title = "warning";
        break;
      case 2:
        title = "app-primary";
        break;
      case 3:
        title = "app-danger";
        break;
    }

    return title;
  }

  getAbsenceIconName(type: EAbsencesType): IconProp {
    var icon!: IconProp;

    switch (type) {
      case 0:
        icon = "umbrella-beach";
        break;
      case 1:
        icon = "business-time";
        break;
      case 2:
        icon = "award";
        break;
      case 3:
        icon = "briefcase-medical";
        break;
    }

    return icon;
  }

  getAbsenceStatusColor(status: EAbsenceStatus): string {
    var color: string = "";

    switch (status) {
      case 0:
        color = "app-primary";
        break;
      case 1:
        color = "app-success";
        break;
      case 2:
        color = "app-danger";
        break;
    }
    return color;
  }

  getAbsenceType(type: EAbsencesType): string {
    var name = "";

    switch (type) {
      case 0:
        name = this.translateService.instant("PAGES.ABSENT_RECORD.TITLE.VACATION");
        break;
      case 1:
        name = this.translateService.instant("PAGES.ABSENT_RECORD.TITLE.TIME_OFF");
        break;
      case 2:
        name = this.translateService.instant("PAGES.ABSENT_RECORD.TITLE.CONTINUING_EDUCATION");
        break;
      case 3:
        name = this.translateService.instant("PAGES.ABSENT_RECORD.TITLE.SICK_LEAVE");
        break;
      case 4:
        name = this.translateService.instant("PAGES.ABSENT_RECORD.TITLE.LEAVE");
        break;
    }

    return name;
  }

  getAbsenceStatus(absence: Absence): string {
    var name = "";
    const status = absence.status;

    switch (status) {
      case 0:
        name = this.translateService.instant("PAGES.ABSENT_RECORD.STATUS.REQUESTED");
        break;
      case 1:
        if (absence.type == EAbsencesType.SICK_LEAVE) {
          name = this.translateService.instant("PAGES.ABSENT_RECORD.STATUS.CHECKED");
        } else {
          name = this.translateService.instant("PAGES.ABSENT_RECORD.STATUS.APPROVED");
        }
        break;
      case 2:
        name = this.translateService.instant("PAGES.ABSENT_RECORD.STATUS.REJECTED");
        break;
    }

    return name;
  }

  getWorkRecordsStartDate(wts: IWorkTimeScheme[]): Date {
    let workTimeSchemes = [...wts];

    workTimeSchemes.sort((a, b) => a.startDate.getTime() - b.startDate.getTime());

    return workTimeSchemes[0].startDate;
  }

  getCarryOverVacationDays(wts: IWorkTimeScheme[]): number {
    let workTimeSchemes = [...wts];

    workTimeSchemes.sort((a, b) => a.startDate.getTime() - b.startDate.getTime());

    return workTimeSchemes[0].carryover_vacations ? workTimeSchemes[0].carryover_vacations : 0;
  }

  canStartWorkRecords(wts: IWorkTimeScheme[]): boolean {
    let workTimeSchemes = [...wts];

    workTimeSchemes.sort((a, b) => a.startDate.getTime() - b.startDate.getTime());

    let startDate = new Date(workTimeSchemes[0].startDate);
    startDate.setHours(7);

    return this.systemTime.getTime() >= startDate.getTime();
  }

  getCurrentBallance(employee: Employee, workRecords: WorkRecord[]): number {
    let startDate = new Date(this.getWorkRecordsStartDate(employee.workTimeSchemes));
    let today = new Date(this.systemTime);
    let shouldHours = 0;
    let isHours = 0;

    if (employee.workTimeSchemes[0].carryover_overtime) {
      isHours += employee.workTimeSchemes[0].carryover_overtime * 60;
    }

    const days: Date[] = [];

    while (!isSameDay(startDate, today)) {
      days.push(new Date(startDate));
      startDate = addDays(startDate, 1);
    }

    days.forEach((day: Date) => {
      isHours += this.isHoursToday(day, workRecords);
      shouldHours += this.shouldHoursToday(employee, day, true);
    });

    return isHours - shouldHours;
  }

  getTotalBalance(employee: Employee, workRecords: WorkRecord[]): number {
    let shouldHours = 0;
    let isHours = this.getCurrentBallance(employee, workRecords);
    let today = new Date(this.systemTime);
    let endDate = addDays(addWeeks(today, 4), 1);

    while (!isSameDay(today, endDate)) {
      var workTimeScheme = this.currentWorkTimeScheme(employee.workTimeSchemes, today);
      var totalWorkingDays = 0;
      var totalWorkingMinutes = 0;

      if (workTimeScheme) {
        totalWorkingDays = Object.values(workTimeScheme.workingDays).filter(Boolean).length;
        totalWorkingMinutes = (workTimeScheme.workingHours! * 60) / totalWorkingDays;
      }

      const IS_DATE_HOLIDAY = this.isDateHoliday(today);
      const IS_ABSENCE = this.isDateAbsence(today);

      if (!IS_DATE_HOLIDAY && !isWeekend(today) && IS_ABSENCE && IS_ABSENCE.type == EAbsencesType.OVERTIME) {
        shouldHours = shouldHours + totalWorkingMinutes;
      }
      today = addDays(today, 1);
    }

    return isHours - shouldHours;
  }

  shouldHoursToday(employee: Employee, date: Date, timeAccount = false): number {
    var shouldHoursToday = 0;
    let afterExitDate = false;
    var workTimeScheme = this.currentWorkTimeScheme(employee.workTimeSchemes, date);

    if (employee.exitDate && isAfter(date, employee.exitDate)) {
      afterExitDate = true;
    }

    if (workTimeScheme && !afterExitDate) {
      const START_DATE = this.getWorkRecordsStartDate(employee.workTimeSchemes);
      shouldHoursToday = this.calculateDailyShouldWorkingMinutes(workTimeScheme, date, START_DATE, timeAccount);
    }
    return shouldHoursToday;
  }

  shouldHoursWeek(employee: Employee, date: Date): number {
    var minutes = 0;
    let afterExitDate = false;
    const START_DATE = this.getWorkRecordsStartDate(employee.workTimeSchemes);

    const monday = startOfWeek(date, { weekStartsOn: 1 });
    const sunday = endOfWeek(date, { weekStartsOn: 1 });

    const weekdays = eachDayOfInterval({ start: monday, end: sunday }).filter((date) => !isWeekend(date));

    weekdays.forEach((day: Date) => {
      if (employee.exitDate && isAfter(day, employee.exitDate)) {
        afterExitDate = true;
      }

      var workTimeScheme = this.currentWorkTimeScheme(employee.workTimeSchemes, day);
      if (workTimeScheme && !afterExitDate) {
        minutes += this.calculateDailyShouldWorkingMinutes(workTimeScheme, day, START_DATE);
      }
    });

    return minutes;
  }

  shouldHoursMonths(employee: Employee, date: Date, timeAccount = false): number {
    var minutes = 0;
    let afterExitDate = false;
    const START_DATE = this.getWorkRecordsStartDate(employee.workTimeSchemes);

    const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    const weekdaysOfMonth = eachDayOfInterval({ start: startOfMonth, end: endOfMonth })
      .filter((date) => !isWeekend(date))
      .filter((date) => isSameMonth(date, startOfMonth));

    weekdaysOfMonth.forEach((day: Date) => {
      if (employee.exitDate && isAfter(day, employee.exitDate)) {
        afterExitDate = true;
      }
      var workTimeScheme = this.currentWorkTimeScheme(employee.workTimeSchemes, day);
      if (workTimeScheme && !afterExitDate) {
        minutes += this.calculateDailyShouldWorkingMinutes(workTimeScheme, day, START_DATE, timeAccount);
      }
    });

    return minutes;
  }

  isHoursToday(date: Date, workRecords: WorkRecord[]) {
    const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
    const endOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
    var todayWorkRecords: WorkRecord[] = [];

    workRecords.forEach((wr) => {
      const { startTime, endTime } = wr;
      if (endTime && startTime > startOfDay && endTime < endOfDay) {
        todayWorkRecords.push(wr);
      }

      if (!endTime && isSameDay(date, startTime)) {
        todayWorkRecords.push(wr);
      }
    });

    return this.calculateWorkingMinutes(todayWorkRecords);
  }

  isHoursWeek(date: Date, workRecords: WorkRecord[]): number {
    var totalWorkingMinutes = 0;
    const monday = startOfWeek(date, { weekStartsOn: 1 });
    const sunday = endOfWeek(date, { weekStartsOn: 1 });

    var weekWorkRecords: WorkRecord[] = [];

    workRecords.forEach((wr) => {
      const { startTime, endTime } = wr;
      if (endTime && startTime > monday && endTime < sunday) {
        weekWorkRecords.push(wr);
      }

      if (!endTime && isSameDay(date, startTime)) {
        weekWorkRecords.push(wr);
      }
    });

    var today = new Date(monday);

    while (!isSameDay(today, addDays(sunday, 1))) {
      const sameDayWorkRecords: WorkRecord[] = [];

      for (let wr of workRecords) {
        if (isSameDay(today, wr.startTime)) {
          sameDayWorkRecords.push(wr);
        }
      }

      totalWorkingMinutes = totalWorkingMinutes + this.calculateWorkingMinutes(sameDayWorkRecords);

      today = addDays(today, 1);
    }

    return totalWorkingMinutes;
  }

  isHoursMonth(date: Date, workRecords: WorkRecord[]): number {
    var totalWorkingMinutes = 0;
    const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59);

    var weekWorkRecords: WorkRecord[] = [];

    workRecords.forEach((wr) => {
      const { startTime, endTime } = wr;
      if (endTime && startTime > startOfMonth && endTime < endOfMonth) {
        weekWorkRecords.push(wr);
      }

      if (!endTime && isSameDay(date, startTime)) {
        weekWorkRecords.push(wr);
      }
    });

    var today = new Date(startOfMonth);

    while (!isSameDay(today, addDays(endOfMonth, 1))) {
      const sameDayWorkRecords: WorkRecord[] = [];

      for (let wr of workRecords) {
        if (isSameDay(today, wr.startTime)) {
          sameDayWorkRecords.push(wr);
        }
      }

      totalWorkingMinutes = totalWorkingMinutes + this.calculateWorkingMinutes(sameDayWorkRecords);

      today = addDays(today, 1);
    }
    return totalWorkingMinutes;
  }

  todayWorkRecords(date: Date, workRecords: WorkRecord[]) {
    const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
    const endOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
    const nextDay = addDays(startOfDay, 1);
    var todayWorkRecords: WorkRecord[] = [];

    workRecords.forEach((wr) => {
      const { startTime, endTime } = wr;
      if (endTime && startTime > startOfDay && endTime < endOfDay) {
        todayWorkRecords.push(wr);
      }

      if (!endTime && startTime > startOfDay && startTime < nextDay) {
        todayWorkRecords.push(wr);
      }
    });

    todayWorkRecords = todayWorkRecords.sort((a, b) => {
      return a.startTime.getTime() - b.startTime.getTime();
    });

    return todayWorkRecords;
  }

  convertMinutesToHHMM(minutes: number) {
    let negativeValue = false;

    if (minutes < 0) {
      minutes *= -1;
      negativeValue = true;
    }

    const hours = Math.floor(minutes / 60);
    let minutesRemainder = minutes % 60;
    minutesRemainder = parseFloat(minutesRemainder.toFixed(0));
    let formattedHours = String(hours).padStart(2, "0");
    let formattedMinutes = String(minutesRemainder).padStart(2, "0");

    negativeValue ? (formattedHours = "-" + formattedHours) : formattedHours;

    return `${formattedHours}:${formattedMinutes}`;
  }

  checkStartedWorkRecord(date: Date, workRecords: WorkRecord[]): WorkRecord | undefined {
    var wr: WorkRecord | undefined = undefined;

    workRecords.forEach((w) => {
      const { startTime, endTime } = w;
      if (isSameDay(date, startTime) && !endTime) {
        wr = w;
      }
    });

    return wr;
  }

  getLastWorkingDay() {
    const tmpDate = new Date(this.systemTime);

    if (tmpDate.getHours() < 7) {
      tmpDate.setDate(tmpDate.getDate() - 1);
    }

    while (this.isDateHoliday(tmpDate)) {
      tmpDate.setDate(tmpDate.getDate() - 1);
    }

    while (this.isDateInRangeOfOtherAbsence(tmpDate, true, "")) {
      tmpDate.setDate(tmpDate.getDate() - 1);
    }

    const day = tmpDate.getDay();
    var workingDay = new Date(this.systemTime);
    switch (day) {
      case 0:
        workingDay = new Date(tmpDate.setDate(tmpDate.getDate() - 2));
        break;
      case 6:
        workingDay = new Date(tmpDate.setDate(tmpDate.getDate() - 1));
        break;
      default:
        workingDay = tmpDate;
    }
    return workingDay;
  }

  isWorkingDay() {
    return this.isDayBookable();
  }

  getWorkingDay(date: Date) {
    const tmpDate = new Date(date);
    while (this.isDateHoliday(tmpDate)) {
      tmpDate.setDate(tmpDate.getDate() + 1);
    }
    const day = tmpDate.getDay();
    var workingDay = date;
    switch (day) {
      case 0:
        workingDay = new Date(tmpDate.setDate(tmpDate.getDate() + 1));
        break;
      case 6:
        workingDay = new Date(tmpDate.setDate(tmpDate.getDate() + 2));
        break;
      default:
        workingDay = tmpDate;
    }
    return workingDay;
  }

  getAbsenceDurationCount(startDate: Date, endDate: Date): number {
    let vacationDaysCount = 1;

    if (isSameDay(startDate, endDate)) {
      return vacationDaysCount;
    }

    while (!isSameDay(startDate, endDate)) {
      if (!isWeekend(startDate) && !this.isDateHoliday(startDate)) {
        vacationDaysCount = vacationDaysCount + 1;
      }
      startDate = addDays(startDate, 1);
    }

    return vacationDaysCount;
  }

  public isDateAbsence(date: Date, absences: Absence[] | undefined = undefined): Absence | undefined {
    let a = undefined;
    let tmpAbsences: Absence[] = [];

    if (absences) {
      tmpAbsences = [...absences];
    } else {
      tmpAbsences = [...this.absences];
    }

    if (tmpAbsences.length > 0) {
      for (const absence of tmpAbsences) {
        let startDate = new Date(absence.startDate);
        let endDate = new Date(absence.endDate);
        let checkDate = new Date(date);

        if (isSameDay(startDate, endDate) && !a) {
          isSameDay(checkDate, startDate) && !this.isDateHoliday(date) && !isWeekend(date) ? (a = absence) : (a = undefined);
        } else {
          endDate = addDays(endDate, 1);
          while (!isSameDay(startDate, endDate) && !a) {
            isSameDay(checkDate, startDate) && !this.isDateHoliday(date) && !isWeekend(date) ? (a = absence) : (a = undefined);
            startDate = addDays(startDate, 1);
          }
        }
      }
    }
    return a;
  }

  private calculateDailyShouldWorkingMinutes(workingSchema: IWorkTimeScheme, date: Date, worRecordStartDate: Date, timeAccount = false) {
    const totalWorkingDays = Object.values(workingSchema.workingDays).filter(Boolean).length;
    var totalWorkingMinutes = (workingSchema.workingHours! * 60) / totalWorkingDays;
    const IS_DATE_HOLIDAY = this.isDateHoliday(date);
    let isDateAbsence = this.isDateAbsence(date);

    date.setHours(7);
    worRecordStartDate.setHours(7);

    const IS_BEFORE_START_DATE = isBefore(date, worRecordStartDate) && !isSameDay(date, worRecordStartDate);

    if (timeAccount == true && isDateAbsence && isDateAbsence.type == EAbsencesType.OVERTIME) {
      isDateAbsence = undefined;
    }

    if (IS_DATE_HOLIDAY || isDateAbsence != undefined || IS_BEFORE_START_DATE) {
      totalWorkingMinutes = 0;
    }

    switch (date.getDay()) {
      case 0:
        totalWorkingMinutes = 0;
        break;
      case 1:
        if (!workingSchema.workingDays.monday) {
          totalWorkingMinutes = 0;
        }
        break;
      case 2:
        if (!workingSchema.workingDays.tuesday) {
          totalWorkingMinutes = 0;
        }
        break;
      case 3:
        if (!workingSchema.workingDays.wednesday) {
          totalWorkingMinutes = 0;
        }
        break;
      case 4:
        if (!workingSchema.workingDays.thursday) {
          totalWorkingMinutes = 0;
        }
        break;
      case 5:
        if (!workingSchema.workingDays.friday) {
          totalWorkingMinutes = 0;
        }
        break;
      case 6:
        totalWorkingMinutes = 0;
        break;
    }

    return totalWorkingMinutes;
  }

  private calculateWorkingMinutes(workingRecords: WorkRecord[]): number {
    workingRecords.sort((a, b) => {
      return a.startTime.getTime() - b.startTime.getTime();
    });

    let sameDayWorkRecords: WorkRecord[] = [...workingRecords];
    let totalWorkingMinutes = 0;
    let pause = 0;
    let dailyWorkingMinutes: any = {};
    const now = new Date(this.systemTime);
    now.setSeconds(0);

    sameDayWorkRecords = this.setEndTimeForCurrentWorkRecord(sameDayWorkRecords, now);
    pause = this.calculatePauseBetweenWorkRecords(sameDayWorkRecords);

    for (const element of sameDayWorkRecords) {
      if (!this.isDayBookable(element.startTime.toISOString())) continue;

      const { startTime, endTime } = element;

      var workingMinutes = 0;

      startTime.setSeconds(0);

      const dateKey = startTime.toISOString().split("T")[0];

      if (!dailyWorkingMinutes[dateKey]) {
        dailyWorkingMinutes[dateKey] = [];
      }

      if (endTime) {
        endTime.setSeconds(0);
        workingMinutes = (endTime!.getTime() - startTime.getTime()) / (1000 * 60);
      } else if (isSameDay(startTime, now) && !isAfter(now, setHours(now, 21))) {
        workingMinutes = (now!.getTime() - startTime.getTime()) / (1000 * 60);
      }

      dailyWorkingMinutes[dateKey].push(workingMinutes);
    }

    for (const dateKey in dailyWorkingMinutes) {
      const dayWorkingMinutes = dailyWorkingMinutes[dateKey];

      const dayTotalMinutes = dayWorkingMinutes.reduce((total: number, minutes: number) => total + minutes, 0);

      let adjustedMinutes = dayTotalMinutes;
      if (dayTotalMinutes > 360 && dayTotalMinutes <= 540 && pause < 30) {
        pause = 30 - pause;
        adjustedMinutes -= pause;
      } else if (dayTotalMinutes > 540 && pause < 45) {
        pause = 45 - pause;
        adjustedMinutes -= pause;
      }

      totalWorkingMinutes += adjustedMinutes;
    }

    totalWorkingMinutes = totalWorkingMinutes > 600 ? 600 : totalWorkingMinutes;

    return Math.round(totalWorkingMinutes);
  }

  private setEndTimeForCurrentWorkRecord(workRecords: WorkRecord[], date: Date): WorkRecord[] {
    if (workRecords.length > 0 && isSameDay(workRecords[0].startTime, date)) {
      for (let i = 0; i < workRecords.length; i++) {
        if (workRecords[i].endTime == undefined) {
          const w: IWorkRecord = {
            id: workRecords[i].id,
            uuid: workRecords[i].uuid,
            editorId: workRecords[i].editorId,
            startTime: workRecords[i].startTime,
            endTime: date,
            comment: workRecords[i].comment,
            commentEditor: workRecords[i].commentEditor,
            notified: workRecords[i].notified,
            status: workRecords[i].status,
            editor: null,
          };

          const tmpWR = new WorkRecord(w);
          workRecords[i] = tmpWR;
        }
      }
      workRecords = this.checkOverlappingWorkRecords(workRecords);
    }
    return workRecords;
  }

  private checkOverlappingWorkRecords(workRecords: WorkRecord[]): WorkRecord[] {
    const updatedWorkRecords: WorkRecord[] = [];

    for (let i = 0; i < workRecords.length; i++) {
      let overlap = false;

      for (let j = 0; j < updatedWorkRecords.length; j++) {
        if (workRecords[i].startTime <= updatedWorkRecords[j].endTime! && workRecords[i].endTime! >= updatedWorkRecords[j].startTime) {
          overlap = true;
          updatedWorkRecords[j] = this.mergeWorkRecords(updatedWorkRecords[j], workRecords[i]);
        }
      }
      if (overlap == false) {
        updatedWorkRecords.push(workRecords[i]);
      }
    }
    return updatedWorkRecords;
  }

  private getHolidays(year: string): IHoliday[] {
    const h: IHoliday[] = [];
    const index = holidays.findIndex((yearHoliday) => yearHoliday.year === +year);
    if (index !== -1) {
      holidays[index].data.forEach((data) => {
        const date = new Date(+year, data.date.month, data.date.day);
        let day: IHoliday = {
          name: data.name,
          date: date,
        };
        h.push(day);
      });
    }
    return h;
  }

  private mergeWorkRecords(existingWorkRecord: WorkRecord, newWorkRecord: WorkRecord) {
    const wr: IWorkRecord = {
      id: existingWorkRecord.id,
      uuid: existingWorkRecord.uuid,
      editorId: existingWorkRecord.editorId,
      startTime: new Date(Math.min(existingWorkRecord.startTime.getTime(), newWorkRecord.startTime.getTime())),
      endTime: new Date(Math.max(existingWorkRecord.endTime!.getTime(), newWorkRecord.endTime!.getTime())),
      comment: existingWorkRecord.comment,
      commentEditor: existingWorkRecord.commentEditor,
      notified: existingWorkRecord.notified,
      status: existingWorkRecord.status,
      editor: null,
    };

    const workRecord: WorkRecord = new WorkRecord(wr);

    return workRecord;
  }

  highlightedAbsenceDates(dateString: string, type = -1, startDate = "", endDate = "") {
    const date = new Date(dateString);

    if (this.isDateHoliday(date)) {
      return {
        textColor: "#ffffff",
        backgroundColor: "#56638a",
      };
    }

    if (
      this.isDateInRangeOfOtherAbsenceType(date, EAbsencesType.VACATION) ||
      ((dateString == startDate || dateString == endDate) && type == EAbsencesType.VACATION)
    ) {
      return {
        textColor: "#ffffff",
        backgroundColor: "#59c28f",
      };
    }

    if (
      this.isDateInRangeOfOtherAbsenceType(date, EAbsencesType.OVERTIME) ||
      ((dateString == startDate || dateString == endDate) && type == EAbsencesType.OVERTIME)
    ) {
      return {
        textColor: "#ffffff",
        backgroundColor: "#ffca22",
      };
    }

    if (
      this.isDateInRangeOfOtherAbsenceType(date, EAbsencesType.TRAINING) ||
      ((dateString == startDate || dateString == endDate) && type == EAbsencesType.TRAINING)
    ) {
      return {
        textColor: "#ffffff",
        backgroundColor: "#4c8dff",
      };
    }

    if (
      this.isDateInRangeOfOtherAbsenceType(date, EAbsencesType.SICK_LEAVE) ||
      ((dateString == startDate || dateString == endDate) && type == EAbsencesType.SICK_LEAVE)
    ) {
      return {
        textColor: "#ffffff",
        backgroundColor: "#F54F29",
      };
    }

    return undefined;
  }

  isDayBookable(dateString: string = formatISO(new Date(this.systemTime)), newAbsence = true, id = "-1") {
    const date = new Date(dateString);
    return !isWeekend(date) && !this.isDateHoliday(date) && !this.isDateInRangeOfOtherAbsence(date, newAbsence, id);
  }

  private calculatePauseBetweenWorkRecords(workRecords: WorkRecord[]): number {
    let wr = [...workRecords];
    wr = wr.filter((record) => record.endTime !== undefined);
    let p = 0;

    if (wr.length == 1) {
      return p;
    }

    for (let i = 1; i < wr.length; i++) {
      const previousEndTime = wr[i - 1].endTime!.getTime();
      const nextStartTime = wr[i].startTime.getTime();

      const timeDifferenceInMillis = Math.abs(nextStartTime - previousEndTime);
      const timeDifferenceInMinutes = timeDifferenceInMillis / (1000 * 60);

      p = p + timeDifferenceInMinutes;
    }
    return p;
  }

  private isDateInRangeOfOtherAbsence(date: Date, newAbsence: boolean, id: string): boolean {
    let highlight = false;

    if (this.absences.length == 0) {
      return highlight;
    }

    const CHECK_DATE = date.setHours(7);

    for (const absence of this.absences) {
      const START_DATE = absence.startDate.setHours(1);
      const END_DATE = absence.endDate.setHours(23);

      if (isSameDay(START_DATE, END_DATE) && isSameDay(START_DATE, CHECK_DATE)) {
        highlight = true;
      }

      if (
        isWithinInterval(CHECK_DATE, {
          start: START_DATE,
          end: END_DATE,
        })
      ) {
        highlight = true;

        if (!newAbsence && absence.id == id) {
          highlight = false;
        }
      }
    }
    return highlight;
  }

  private isDateInRangeOfOtherAbsenceType(date: Date, type: EAbsencesType): boolean {
    let highlight = false;

    if (this.absences.length == 0 || isWeekend(date)) {
      return highlight;
    }

    const CHECK_DATE = date.setHours(7);

    for (const absence of this.absences) {
      const START_DATE = absence.startDate.setHours(7);
      const END_DATE = absence.endDate.setHours(7);

      if (absence.type == type) {
        if (isSameDay(START_DATE, END_DATE) && isSameDay(START_DATE, CHECK_DATE)) {
          highlight = true;
        }

        if (
          isWithinInterval(CHECK_DATE, {
            start: START_DATE,
            end: END_DATE,
          })
        ) {
          highlight = true;
        }
      }
    }
    return highlight;
  }

  private openWorkRecords(workRecords: IWorkRecord[]): boolean {
    let openWorkRecord = false;

    if (workRecords.length > 0) {
      workRecords.forEach((workRecord, index) => {
        if (workRecord.startTime && !workRecord.endTime) {
          openWorkRecord = true;
        }
      });
    }

    return openWorkRecord;
  }

  private compareDateWithTime(date: Date, time: ITime): number {
    const timeAsDate = setHours(setMinutes(setSeconds(new Date(this.systemTime), time.seconds), time.minutes), time.hours);

    return compareAsc(date, timeAsDate);
  }

  private convertTimeFromApi(time: string): ITime {
    let t: ITime = {
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
    const timeComponents: string[] = time.split(":");

    t.hours = +timeComponents[0];
    t.minutes = +timeComponents[1];
    t.seconds = +timeComponents[2];

    return t;
  }
}
