import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, delay, interval, map, of, startWith, switchMap, tap, withLatestFrom } from "rxjs";
import { timeActions } from "./actions";
import { TimeService } from "./services/time.service";
import { Store } from "@ngrx/store";
import * as UIActions from "src/app/core/store/ui/ui.actions";
import { uiActions } from "../ui/actions";
import { ITimeState } from "./interfaces/time.interface";
import { selectUseCustomTime } from "../settings/reducer";
import { getUnixTime } from "date-fns";
import { selectCustomTimeDiff } from "./reducer";

@Injectable()
export class TimeFeatureEffects {
  serverTime: Date = new Date();

  fetchTime$ = createEffect(() =>
    this.actions$.pipe(
      ofType(timeActions.fetchTime),
      tap(() => {
        this.store.dispatch(uiActions.fetchSystemTime());
      }),
      switchMap(() => {
        return this.timeService.fetchSystemTime().pipe(
          map((systemTime: ITimeState) => {
            this.serverTime = systemTime.systemTime;
            this.store.dispatch(timeActions.fetchTimeSuccess({ dates: systemTime }));
            return timeActions.updateTime();
          }),
          catchError((error: any) => {
            this.store.dispatch(uiActions.failedFetching());
            return of(new UIActions.Error(error));
          })
        );
      })
    )
  );

  setCustomTime$ = createEffect(() =>
    this.actions$.pipe(
      ofType(timeActions.setCustomTime),
      map((action) => {
        let timeDiff = this.timeService.getCustomTimeDiff(action.unixDate, getUnixTime(this.serverTime));
        return timeActions.setCustomTimeDiff({ timeDiff: timeDiff });
      })
    )
  );

  setCustomTimeDiff$ = createEffect(() =>
    this.actions$.pipe(
      ofType(timeActions.setCustomTimeDiff),
      map((action) => {
        this.store.dispatch(timeActions.setCustomSuccess());
        return timeActions.updateTime();
      })
    )
  );

  resetCustomTime$ = createEffect(() =>
    this.actions$.pipe(
      ofType(timeActions.resetCustomTime),
      map((action) => {
        this.store.dispatch(timeActions.setCustomSuccess());
        return timeActions.updateTime();
      })
    )
  );

  setCustomTimeSucces$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(timeActions.setCustomSuccess),
        switchMap((action) =>
          of(action).pipe(
            delay(1500),
            withLatestFrom(this.store.select(selectCustomTimeDiff)),
            switchMap(([action, latestUpdate]) => {
              return this.timeService.updateServerCustomTimeOn(latestUpdate).pipe(
                map((serverDate: Date) => {
                  this.serverTime = serverDate;
                }),
                catchError((error: any) => {
                  return of(new UIActions.Error(error));
                })
              );
            })
          )
        )
      ),
    { dispatch: false }
  );

  updateTime$ = createEffect(() =>
    this.actions$.pipe(
      ofType(timeActions.updateTime),
      tap(() => {
        this.store.dispatch(uiActions.successFetchingSystemTime());
      }),
      switchMap(() =>
        interval(1000).pipe(
          startWith(0),
          withLatestFrom(this.store.select(selectUseCustomTime), this.store.select(selectCustomTimeDiff)),
          map(([action, useCustomTime, timeDiff]) => {
            this.serverTime.setSeconds(this.serverTime.getSeconds(), 1000);
            let date = new Date(this.serverTime);
            if (useCustomTime) {
              date = new Date((getUnixTime(this.serverTime) + timeDiff) * 1000);
            }
            return timeActions.setTime({ date: date });
          })
        )
      )
    )
  );

  constructor(private actions$: Actions, private timeService: TimeService, private store: Store) {}
}
