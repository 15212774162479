import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {Store} from '@ngrx/store';
import {addDays, format, isSameDay} from 'date-fns';
import {ENotificationStatus} from 'src/app/core/enums/notification.enum';
import {Accounts} from 'src/app/core/interfaces/account.types';
import {NotificationAbsence} from 'src/app/core/models/notification-absence.model';
import {IAppState} from 'src/app/core/store/app/app.reducer';
import {NotificationsState} from 'src/app/core/store/notifications/notifications.reducer';
import {SharedService} from '../../services/shared.service';
import {NotificationTypes} from 'src/app/core/interfaces/notification.interface';
import * as NotificationActions from 'src/app/core/store/notifications/notifications.actions';
import {NotificationWorkRecord} from 'src/app/core/models/notification-workRecord.model';
import {TranslateService} from '@ngx-translate/core';
import {NGXLogger} from 'ngx-logger';
import {IonItemSliding, IonList} from '@ionic/angular';

@Component({
  selector: 'app-notification-overview',
  templateUrl: './notification-overview.component.html',
  styleUrls: ['./notification-overview.component.scss'],
})
export class NotificationOverviewComponent implements OnInit, OnChanges {
  @Input() account: Accounts | undefined;
  @Input() notificationState!: NotificationsState;
  public notifications: NotificationTypes[] = [];
  private ionSlideCloseTimeout: any;

  constructor(
    private logger: NGXLogger,
    private translateService: TranslateService,
    private store: Store<IAppState>,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.logger.info('OnInit: Initialize notification overview component');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (this.notificationState) {
        this.notifications = [];

        this.notifications.push(
          ...this.notificationState.notifications.absences.filter(
            (notification) => notification.status == ENotificationStatus.UNREAD
          )
        );

        this.notifications.push(
          ...this.notificationState.notifications.workRecords.filter(
            (notification) => notification.status == ENotificationStatus.UNREAD
          )
        );

        this.sortNotifications();
      }
    }
  }

  swipeHandler(event: any, notification: NotificationTypes) {
    if (event.detail.side == 'start') {
      this.updateNotification(notification);
    }

    if (event.detail.side == 'end') {
      this.deleteNotification(notification);
    }
  }

  updateNotification(notification: NotificationTypes) {
    this.logger.info(
      `Click: Mark notification with id "${notification.id}" as read`
    );
    let updatedNotification =
      this.sharedService.createNotificationFromInterface(notification);
    updatedNotification.status = ENotificationStatus.READ;

    if (notification instanceof NotificationAbsence) {
      this.store.dispatch(
        new NotificationActions.UpdateAbsenceNotification(updatedNotification)
      );
    }

    if (notification instanceof NotificationWorkRecord) {
      this.store.dispatch(
        new NotificationActions.UpdateWorkRecordNotification(
          updatedNotification
        )
      );
    }
  }

  deleteNotification(notification: NotificationTypes) {
    this.logger.info(`Click: Delete notification with id "${notification.id}"`);
    if (notification instanceof NotificationAbsence) {
      this.store.dispatch(
        new NotificationActions.DeleteAbsenceNotification(notification)
      );
    }

    if (notification instanceof NotificationWorkRecord) {
      this.store.dispatch(
        new NotificationActions.DeleteWorkRecordNotification(notification)
      );
    }
  }

  getNotificationMessage(notification: NotificationTypes): string {
    let message = '';

    if (notification instanceof NotificationAbsence) {
      switch (notification.absenceNotificationType) {
        case 0:
          switch (notification.absenceType) {
            case 0:
              message = `${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.NEW_VACATION_REQUEST'
              )} ${this.getNotificationAbsenceDate(
                notification.startDate,
                notification.endDate
              )} ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.WAS_FROM'
              )} ${notification.author.firstName} ${
                notification.author.lastName
              } ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.CREATED_FOR_YOU'
              )}`;
              break;
            case 1:
              message = `${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.YOUR_COMPENSATION_REQUEST_FOR'
              )} ${this.getNotificationAbsenceDate(
                notification.startDate,
                notification.endDate
              )} ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.WAS_FROM'
              )} ${notification.author.firstName} ${
                notification.author.lastName
              } ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.CREATED_FOR_YOU'
              )}`;
              break;
            case 2:
              message = `${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.NEW_TRAINING_REQUEST_FOR'
              )} ${this.getNotificationAbsenceDate(
                notification.startDate,
                notification.endDate
              )} ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.WAS_FROM'
              )} ${notification.author.firstName} ${
                notification.author.lastName
              } ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.CREATED_FOR_YOU'
              )}`;
              break;
            case 3:
              message = `${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.NEW_SICK_LEAVE_FOR'
              )} ${this.getNotificationAbsenceDate(
                notification.startDate,
                notification.endDate
              )} ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.FROM'
              )} ${notification.author.firstName} ${
                notification.author.lastName
              } ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.CREATED_FOR_YOU'
              )}`;
              break;
          }

          break;
        case 1:
          switch (notification.absenceType) {
            case 0:
              message = `${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.YOUR_LEAVE_REQUEST_FOR'
              )} ${this.getNotificationAbsenceDate(
                notification.startDate,
                notification.endDate
              )} ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.WAS_FROM'
              )} ${notification.author.firstName} ${
                notification.author.lastName
              } ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.APPROVED'
              )}`;
              break;
            case 1:
              message = `${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.YOUR_COMPENSATION_REQUEST_FOR'
              )} ${this.getNotificationAbsenceDate(
                notification.startDate,
                notification.endDate
              )} ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.WAS_FROM'
              )} ${notification.author.firstName} ${
                notification.author.lastName
              } ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.APPROVED'
              )}`;
              break;
            case 2:
              message = `${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.YOUR_TRAINING_REQUEST_FOR'
              )} ${this.getNotificationAbsenceDate(
                notification.startDate,
                notification.endDate
              )} ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.WAS_FROM'
              )} ${notification.author.firstName} ${
                notification.author.lastName
              } ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.APPROVED'
              )}`;
              break;
            case 3:
              message = `${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.YOUR_SICK_LEAVE_FOR'
              )} ${this.getNotificationAbsenceDate(
                notification.startDate,
                notification.endDate
              )} ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.WAS_FROM'
              )} ${notification.author.firstName} ${
                notification.author.lastName
              } ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.APPROVED'
              )}`;
              break;
          }

          break;
        case 2:
          switch (notification.absenceType) {
            case 0:
              message = `${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.YOUR_LEAVE_REQUEST_FOR'
              )} ${this.getNotificationAbsenceDate(
                notification.startDate,
                notification.endDate
              )} ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.WAS_FROM'
              )} ${notification.author.firstName} ${
                notification.author.lastName
              } ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.REJECTED'
              )}`;
              break;
            case 1:
              message = `${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.YOUR_COMPENSATION_REQUEST_FOR'
              )} ${this.getNotificationAbsenceDate(
                notification.startDate,
                notification.endDate
              )} ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.WAS_FROM'
              )} ${notification.author.firstName} ${
                notification.author.lastName
              } ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.REJECTED'
              )}`;
              break;
            case 2:
              message = `${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.YOUR_TRAINING_REQUEST_FOR'
              )} ${this.getNotificationAbsenceDate(
                notification.startDate,
                notification.endDate
              )} ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.WAS_FROM'
              )} ${notification.author.firstName} ${
                notification.author.lastName
              } ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.REJECTED'
              )}`;
              break;
            case 3:
              message = `${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.YOUR_SICK_LEAVE_WAS_CREATED_BY'
              )} ${this.getNotificationAbsenceDate(
                notification.startDate,
                notification.endDate
              )} ${notification.author.firstName} ${
                notification.author.lastName
              } ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.REJECTED'
              )}`;
              break;
          }

          break;
        case 3:
          switch (notification.absenceType) {
            case 0:
              message = `${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.YOUR_LEAVE_REQUEST_FOR'
              )} ${this.getNotificationAbsenceDate(
                notification.startDate,
                notification.endDate
              )} ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.WAS_FROM'
              )} ${notification.author.firstName} ${
                notification.author.lastName
              } ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.DELETED'
              )}`;
              break;
            case 1:
              message = `${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.YOUR_COMPENSATION_REQUEST_FOR'
              )} ${this.getNotificationAbsenceDate(
                notification.startDate,
                notification.endDate
              )} ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.WAS_FROM'
              )} ${notification.author.firstName} ${
                notification.author.lastName
              } ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.DELETED'
              )}`;
              break;
            case 2:
              message = `${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.YOUR_TRAINING_REQUEST_FOR'
              )} ${this.getNotificationAbsenceDate(
                notification.startDate,
                notification.endDate
              )} ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.WAS_FROM'
              )} ${notification.author.firstName} ${
                notification.author.lastName
              } ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.DELETED'
              )}`;
              break;
            case 3:
              message = `${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.YOUR_SICK_LEAVE_FOR'
              )} ${this.getNotificationAbsenceDate(
                notification.startDate,
                notification.endDate
              )} ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.WAS_FROM'
              )} ${notification.author.firstName} ${
                notification.author.lastName
              } ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.DELETED'
              )}`;
              break;
          }

          break;
        case 4:
          switch (notification.absenceType) {
            case 0:
              message = `${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.YOUR_LEAVE_REQUEST_FOR'
              )} ${this.getNotificationAbsenceDate(
                notification.startDate,
                notification.endDate
              )} ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.WAS_FROM'
              )} ${notification.author.firstName} ${
                notification.author.lastName
              } ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.MODIFIED'
              )}`;
              break;
            case 1:
              message = `${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.YOUR_COMPENSATION_REQUEST_FOR'
              )} ${this.getNotificationAbsenceDate(
                notification.startDate,
                notification.endDate
              )} ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.WAS_FROM'
              )} ${notification.author.firstName} ${
                notification.author.lastName
              } {${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.MODIFIED'
              )}`;
              break;
            case 2:
              message = `${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.YOUR_TRAINING_REQUEST_FOR'
              )} ${this.getNotificationAbsenceDate(
                notification.startDate,
                notification.endDate
              )} ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.WAS_FROM'
              )} ${notification.author.firstName} ${
                notification.author.lastName
              } ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.MODIFIED'
              )}`;
              break;
            case 3:
              message = `${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.YOUR_SICK_LEAVE_FOR'
              )} ${this.getNotificationAbsenceDate(
                notification.startDate,
                notification.endDate
              )} ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.WAS_FROM'
              )} ${notification.author.firstName} ${
                notification.author.lastName
              } ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.MODIFIED'
              )}`;
              break;
          }

          break;
        case 5:
          switch (notification.absenceType) {
            case 0:
              message = `${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.A_NOTE_WAS_CREATED_BY'
              )} ${notification.author.firstName} ${
                notification.author.lastName
              } ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.TO_YOUR_LEAVE_REQUEST_FOR'
              )} ${this.getNotificationAbsenceDate(
                notification.startDate,
                notification.endDate
              )} ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.ADDED'
              )}`;
              break;
            case 1:
              message = `${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.A_NOTE_WAS_CREATED_BY'
              )} ${notification.author.firstName} ${
                notification.author.lastName
              } ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.TO_YOUR_COMPENSATION_REQUEST_FOR'
              )} ${this.getNotificationAbsenceDate(
                notification.startDate,
                notification.endDate
              )} ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.ADDED'
              )}`;
              break;
            case 2:
              message = `${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.A_NOTE_WAS_CREATED_BY'
              )} ${notification.author.firstName} ${
                notification.author.lastName
              } ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.TO_YOUR_TRAINING_REQUEST_FOR'
              )} ${this.getNotificationAbsenceDate(
                notification.startDate,
                notification.endDate
              )} ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.ADDED'
              )}`;
              break;
            case 3:
              message = `${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.A_NOTE_WAS_CREATED_BY'
              )} ${notification.author.firstName} ${
                notification.author.lastName
              } ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.TO_YOUR_SICK_LEAVE_FOR'
              )} ${this.getNotificationAbsenceDate(
                notification.startDate,
                notification.endDate
              )} ${this.translateService.instant(
                'COMPONENTS.NOTIFICATIONS.ADDED'
              )}`;
              break;
          }

          break;
      }
    }

    if (notification instanceof NotificationWorkRecord) {
      switch (notification.workRecordType) {
        case 0:
          message = `${this.translateService.instant(
            'COMPONENTS.NOTIFICATIONS.A_NEW_WORK_TIME_FOR'
          )} ${this.getNotificationWorkRecordDate(
            notification.workRecordDate
          )} ${this.translateService.instant(
            'COMPONENTS.NOTIFICATIONS.WAS_FROM'
          )} ${notification.author.firstName} ${
            notification.author.lastName
          } ${this.translateService.instant(
            'COMPONENTS.NOTIFICATIONS.CREATED_FOR_YOU'
          )}`;
          break;
        case 1:
          message = `${this.translateService.instant(
            'COMPONENTS.NOTIFICATIONS.A_WORK_TIME_FOR'
          )} ${this.getNotificationWorkRecordDate(
            notification.workRecordDate
          )} ${this.translateService.instant(
            'COMPONENTS.NOTIFICATIONS.WAS_FROM'
          )} ${notification.author.firstName} ${
            notification.author.lastName
          } ${this.translateService.instant(
            'COMPONENTS.NOTIFICATIONS.DELETED'
          )}`;
          break;
        case 2:
          message = `${this.translateService.instant(
            'COMPONENTS.NOTIFICATIONS.A_WORK_TIME_FOR'
          )} ${this.getNotificationWorkRecordDate(
            notification.workRecordDate
          )} ${this.translateService.instant(
            'COMPONENTS.NOTIFICATIONS.WAS_FROM'
          )} ${notification.author.firstName} ${
            notification.author.lastName
          } ${this.translateService.instant(
            'COMPONENTS.NOTIFICATIONS.MODIFIED'
          )}`;
          break;
      }
    }

    return message;
  }

  getIconColor(notification: NotificationTypes): string {
    var title = '';

    if (notification instanceof NotificationAbsence) {
      switch (notification.absenceNotificationType) {
        case 0:
          title = 'medium';
          break;
        case 1:
          title = 'app-success';
          break;
        case 2:
          title = 'app-danger';
          break;
        case 3:
          title = 'app-danger';
          break;
        case 4:
          title = 'app-primary';
          break;
        case 5:
          title = 'warning';
          break;
      }
    }

    if (notification instanceof NotificationWorkRecord) {
      switch (notification.workRecordType) {
        case 0:
          title = 'app-success';
          break;
        case 1:
          title = 'app-danger';
          break;
        case 2:
          title = 'app-primary';
          break;
      }
    }

    return title;
  }

  getIconName(notification: NotificationTypes): IconProp {
    var icon!: IconProp;

    if (notification instanceof NotificationAbsence) {
      switch (notification.absenceNotificationType) {
        case 0:
          icon = 'calendar-plus';
          break;
        case 1:
          icon = 'calendar-check';
          break;
        case 2:
          icon = 'calendar-xmark';
          break;
        case 3:
          icon = 'calendar-minus';
          break;
        case 4:
          icon = 'calendar-day';
          break;
        case 5:
          icon = 'note-sticky';
          break;
      }
    }

    if (notification instanceof NotificationWorkRecord) {
      icon = 'business-time';
    }

    return icon;
  }

  getNotificationCreatedDate(date: Date) {
    let d = '';
    if (isSameDay(this.sharedService.serverTime, date)) {
      d = format(date, 'HH:mm');
    } else if (isSameDay(addDays(this.sharedService.serverTime, -1), date)) {
      d = this.translateService.instant('COMPONENTS.NOTIFICATIONS.YESTERDAY');
    } else {
      d = format(date, 'dd.MM');
    }
    return d;
  }

  getNotificationAbsenceDate(startDate: Date, endDate: Date): string {
    let date = '';

    if (isSameDay(startDate, endDate)) {
      date = format(startDate, 'dd.MM.yy');
    } else {
      date =
        format(startDate, 'dd.MM.yy') + ' - ' + format(endDate, 'dd.MM.yy');
    }

    return date;
  }

  getNotificationWorkRecordDate(workRecordDate: Date): string {
    let date = '';

    date = format(workRecordDate, 'dd.MM.yy');

    return date;
  }

  itemDragged(list: IonList) {
    clearTimeout(this.ionSlideCloseTimeout);
    this.ionSlideCloseTimeout = setTimeout(() => {
      list.closeSlidingItems();
    }, 5000);
  }

  getNotificationType(notification: NotificationTypes): string {
    let type = '';

    if (notification instanceof NotificationAbsence) {
      type = 'absence';
    }

    if (notification instanceof NotificationWorkRecord) {
      type = 'workRecord';
    }

    return type;
  }

  getNotificationEditor(notification: NotificationTypes): string {
    let editor = `${notification.author.firstName} ${notification.author.lastName}`;

    return editor;
  }

  getNotificationDate(notification: NotificationTypes): string {
    let date = '';

    if (notification instanceof NotificationAbsence) {
      date = this.getNotificationAbsenceDate(
        notification.startDate,
        notification.endDate
      );
    }

    if (notification instanceof NotificationWorkRecord) {
      date = format(notification.workRecordDate, 'dd.MM.yy');
    }

    return date;
  }

  getNotificationStatusType(notification: NotificationTypes): string {
    let type = '';

    if (notification instanceof NotificationAbsence) {
      switch (notification.absenceNotificationType) {
        case 0:
          type = 'created';
          break;
        case 1:
          type = 'approved';
          break;
        case 2:
          type = 'rejected';
          break;
        case 3:
          type = 'deleted';
          break;
        case 4:
          type = 'modified';
          break;
        case 5:
          type = 'note';
          break;
      }
    }

    if (notification instanceof NotificationWorkRecord) {
      switch (notification.workRecordType) {
        case 0:
          type = 'created';
          break;
        case 1:
          type = 'deleted';
          break;
        case 2:
          type = 'modified';
          break;
      }
    }

    return type;
  }

  private sortNotifications() {
    this.notifications.sort((a, b) => {
      return b.created!.getTime() - a.created!.getTime();
    });
  }
}
